<template>
    <el-dialog :visible="visible" :close-on-click-modal="false" width="800px" @close="handleCancel">
        <template slot="title">
            <div class="stock-title">
                <div style="width: 80%;">缺货登记</div>
                <el-switch style="text-align:right;" v-model="checkByCopy" active-text="复制登记">
                </el-switch>
            </div>
        </template>
        <el-input v-if="checkByCopy" type="textarea" v-model="form.content"
            :placeholder="form.isSimple ? `请输入${form.isOutStock ? '缺货' : '出库'}内容，示例：\nHH1234 1\nHH1234*10` : `请输入${form.isOutStock ? '缺货' : '出库'}内容，示例：\nHH1234 红色 38\nHH1234 黑色 39*1 40*2`"
            :autosize="{ minRows: 10 }" />
        <el-table v-if="!checkByCopy" ref="specTable" :data="tableData || []" border>
            <el-table-column label="货号" width="135" header-align="center" align="center">
                <template slot-scope="{ row }">
                    <el-checkbox v-model="row._Checked" @change="handleItemNoOutStock($event, row)">
                        {{ row.ItemNo }}
                    </el-checkbox>
                </template>
            </el-table-column>
            <el-table-column label="规格*待提数(出库数量)" header-align="center" align="left">
                <template slot-scope="{ row }">
                    <el-tag v-for="(detail, detailIdx) in row.SpecDetails" :key="detailIdx" type="primary" size="medium" effect="light">
                        <span>{{ detail.SpecName ? detail.SpecName : '无规格' }}*{{ detail.Num }}</span>
                        <el-input v-model="detail._EditNum" style="width:50px;" @change="updateItemNum(row)" v-format.int></el-input>
                    </el-tag>
                </template>
            </el-table-column>
        </el-table>
        <div slot="footer" class="dialog-footer">
            <span style="float:left;margin-left:10px" v-if="!checkByCopy">
                <el-button type="danger" style="margin-right:10px;padding:10px 20px;" @click="handleAllOutStock">全部缺货</el-button>
                批量修改：
                <el-input placeholder="请输入出库数量" v-model="batchEditNum" @change="batchEditNumChange" style="width:130px;" v-format.int />
            </span>
            <span style="float:left;margin-left:10px" v-if="checkByCopy">
                <el-switch v-model="form.isSimple" active-text="简洁版" />
                <el-switch style="margin-left: 10px;" v-model="form.isOutStock" active-text="缺货数量" />
            </span>
            <el-button type="default" @click="handleCancel">取消</el-button>
            <el-button type="primary" @click="handleSave" :loading="saveFlag">确定</el-button>
        </div>
    </el-dialog>
</template>

<script>
import { submit } from "@/api/base";
export default {
    name: "EditOutStock",
    data() {
        return {
            visible: false,
            tableData: [],
            saveFlag: false,
            batchEditNum: '',
            form: {
                Details: [],
                StoreID: null,
                content: '',
                isSimple: false,
                isOutStock: true
            },
            checkByCopy: true,
        };
    },
    methods: {
        init(storeId, details) {
            this.form.StoreID = storeId;
            this.form.Details = [...details];
            this.buildData();
            this.visible = true;
        },
        updateItemNum(row) {
            var hasNum = row.SpecDetails.some(x => typeof (x._EditNum) != 'undefined' && x._EditNum != '' && parseInt(x._EditNum) > 0);
            row._Checked = hasNum;
        },
        batchEditNumChange() {
            var that = this;
            if (that.batchEditNum == undefined || that.batchEditNum == '' || isNaN(parseInt(that.batchEditNum))) {
                return;
            }
            that.tableData.forEach(i => {
                i.SpecDetails.forEach(x => {
                    x._EditNum = parseInt(that.batchEditNum);
                });
            });
        },
        handleAllOutStock() {
            var that = this;
            that.tableData.forEach(i => {
                i._Checked = false;
                i.SpecDetails.forEach(x => {
                    x._EditNum = 0;
                });
            });
        },
        handleItemNoOutStock(isChecked, row) {
            row.SpecDetails.forEach(x => {
                if (!isChecked) {
                    x._EditNum = 0;
                } else {
                    if (x._EditNum == 0 || x._EditNum == '0') {
                        x._EditNum = x._OldNum;
                    }
                }
            });
        },
        buildData() {
            var that = this;
            that.tableData = [];
            //排序
            that.form.Details.forEach(i => {
                var detail = { ...i };
                var num = (parseInt(detail.Num) - parseInt(detail.OutStockNum));
                detail._EditNum = num >= 0 ? num : 0;
                detail._OldNum = detail._EditNum;
                var rows = that.tableData.filter(j => j.ProductID == i.ProductID);
                if (rows.length > 0) {
                    rows[0].SpecDetails.push(detail);
                    if (detail._EditNum > 0) {
                        rows[0]._Checked = true;
                    }
                } else {
                    let row = { ProductID: i.ProductID, ItemNo: i.ItemNo, _Checked: detail._EditNum > 0, SpecDetails: [] };
                    row.SpecDetails.push(detail);
                    that.tableData.push(row);
                }
            });
        },
        async handleSave() {
            var param = { ...this.form };
            if (this.detailTab == "fill") {
                var hasChanged = false;
                var errors = [];
                this.tableData.forEach(i => {
                    i.SpecDetails.forEach(x => {
                        if (isNaN(parseInt(x._EditNum)) || parseInt(x._EditNum) < 0) {
                            errors.push(`货号【${i.ItemNo}】规格【${x.SpecName ? x.SpecName : '无规格'}】出库数量不能为空或小于0`);
                        }
                        else {
                            param.Details.forEach(y => {
                                let lastNum = y.Num - parseInt(x._EditNum);
                                if (y.ProductID == x.ProductID && y.SpecID == x.SpecID && y.OutStockNum != lastNum) {
                                    y.OutStockNum = lastNum;
                                    hasChanged = true;
                                }
                            });
                        }
                    });
                });
                if (errors.length > 0) {
                    this.$baseMessage(errors.join('\r\n'), "error");
                    return;
                }
                if (!hasChanged) {
                    this.$baseMessage('数据未变动', 'error');
                    return;
                }
            }
            if (this.saveFlag) {
                this.$baseMessage("保存中", "error");
                return;
            }
            this.saveFlag = true;
            let { data } = await submit("/api/stallCashier/save", param);
            this.saveFlag = false;
            if (data) {
                this.$baseMessage(data, "error");
            }
            else {
                this.$baseMessage('保存成功', "success");
                this.$emit("handleSuccess");
                this.handleCancel();
            }
        },
        handleCancel() {
            this.tableData = [];
            this.batchEditNum = 0;
            this.form = {
                Details: [],
                StoreID: null,
                content: '',
                isSimple: false,
                isOutStock: true
            };
            this.visible = false;
        }
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    .el-tag {
        height: 30px;
        margin-left: 5px !important;
        margin-bottom: 5px !important;

        .el-input__inner {
            height: 22px;
            line-height: 22px;
            padding: 0 5px;
            text-align: center;
            margin-left: 5px;
        }
    }

    .stock-title {
        width: calc(100% - 40px);

        &>div {
            display: inline-block;
        }
    }
}
</style>