<template>
    <el-container class="detail-main">
        <el-header height="40px" style="padding: 0 2px;">
            <el-tabs v-model="detailTab" type="card">
                <el-tab-pane v-if="source == 'proxy'" label="代发信息" name="proxy" />
                <el-tab-pane label="按货号" name="item" />
                <el-tab-pane label="按规格" name="spec" />
            </el-tabs>
            <div class="operate1" v-if="source != 'proxy' && status == 'waitTake' && detail.ItemDetails">
                <el-button type="primary" plain @click="showOnekeyOutStock">一键缺货</el-button>
                <el-button type="danger" plain @click="showAddRefund">退货</el-button>
            </div>
        </el-header>
        <el-header v-if="detailTab != 'proxy'" height="31px">
            <el-input v-model="searchItemNo" placeholder="输入货号筛选" @input="filterDetails"></el-input>
        </el-header>
        <el-main>
            <el-container id="detail-main">
                <el-main>
                    <el-table v-show="detailTab == 'item'" ref="itemTable" :data="detail.ItemDetails || []" border :row-class-name="getRowClass"
                        height="100%">
                        <el-table-column label="货号" align="center">
                            <template slot-scope="{ row }">
                                <span v-if="row.Type == 'refund'">【退】</span>{{ row.ItemNo }}
                                <span class="el-icon-edit-outline" @click="showEditPrice(row, 'item')"
                                    v-if="row.Type != 'refund' && status != 'taked' && row.TakeStatus == 0" />
                            </template>
                        </el-table-column>
                        <el-table-column prop="Num" label="数量" align="center">
                            <template slot-scope="{ row }">
                                {{ row.Num }}
                                <span v-if="row.OutStockNum > 0">{{ '[缺' + row.OutStockNum + ']' }}</span>
                                <span v-if="row.OutStockNum < 0">{{ '[加' + -row.OutStockNum + ']' }}</span>
                                <span class="el-icon-edit-outline" @click="showOutStockDlg(row)"
                                    v-if="row.Type != 'refund' && status == 'waitTake'"></span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="Price" head-cell-class-name="column_price" class-name="column_price" label="商品价"
                            v-if="printConfig.bill.showTablePrice" align="center" />
                        <el-table-column label="单价" align="center">
                            <template slot-scope="{ row }">
                                <el-input v-if="row.Type == 'refund' && row.TakeStatus == 0" v-model="row.SettlePrice" @change="editRefundPrice(row)"
                                    v-format.float></el-input>
                                <span v-else>
                                    {{ row.SettlePrice | decimalFormat }}
                                    <span class="el-icon-edit-outline" @click="showEditPrice(row, 'item')"
                                        v-if="row.Type != 'refund' && status == 'waitTake' && row.TakeStatus == 0" />
                                </span>
                            </template>
                        </el-table-column>
                        <el-table-column label="金额" align="center">
                            <template slot-scope="{ row }">
                                {{ row.Payment | decimalFormat }}
                            </template>
                        </el-table-column>
                    </el-table>
                    <el-table v-show="detailTab == 'spec'" ref="specTable" :data="detail.Details || []" height="100%" :row-class-name="getRowClass"
                        border @select="selectSpecRow" @select-all="selectAllSpecRow">
                        <el-table-column v-if="source != 'proxy'" type="selection" :width="28" align="center"
                            :selectable="(row) => { return status == 'waitTake' && row.Type != 'refund' && (row.Num - row.OutStockNum - row.TakedNum) != 0 }" />
                        <el-table-column label="货号-规格" width="135" header-align="center" align="left">
                            <template slot-scope="{ row }">
                                <span v-if="row.Type == 'refund'">【退】</span>
                                {{ row.ItemNo }}{{ row.SpecName ? '，' : '' }}{{ row.SpecName }}
                            </template>
                        </el-table-column>
                        <el-table-column label="出库量" align="center">
                            <template slot-scope="{ row, column, $index }">
                                {{ row.Num }}
                                <span v-if="row.OutStockNum > 0">{{ '[缺' + row.OutStockNum + ']' }}</span>
                                <span v-if="row.OutStockNum < 0">{{ '[加' + -row.OutStockNum + ']' }}</span>
                                <span v-if="status == 'waitTake'" class="el-icon-edit-outline" @click="showEditNum(row, column, $index)"></span>
                            </template>
                        </el-table-column>
                        <el-table-column v-if="source != 'proxy'" label="待提" align="center" width="70">
                            <template slot-scope="{ row }">
                                <span v-if="status != 'waitTake' || row.Type == 'refund'">{{ row.TakeNum }}</span>
                                <el-input v-else v-model.number="row.TakeNum" @input="editTakeNum(row)" :disabled="row.TakeNum == 0"></el-input>
                            </template>
                        </el-table-column>
                        <el-table-column v-if="source != 'proxy'" prop="TakedNum" label="已提" align="center" />
                        <el-table-column prop="Price" head-cell-class-name="column_price" class-name="column_price" label="商品价"
                            v-if="printConfig.bill.showTablePrice" align="center" />
                        <el-table-column label="单价" align="center">
                            <template slot-scope="{ row }">
                                <el-input v-if="row.Type == 'refund' && row.TakeStatus == 0" v-model="row.SettlePrice"
                                    @change="editRefundPrice(row, 'spec')" v-format.float></el-input>
                                <span v-else>
                                    {{ row.SettlePrice | decimalFormat }}
                                    <span class="el-icon-edit-outline" @click="showEditPrice(row, 'item')"
                                        v-if="row.Type != 'refund' && status == 'waitTake' && row.TakeStatus == 0" />
                                </span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="Payment" label="金额" align="center" />
                        <el-table-column v-if="status == 'waitTake'" label="操作" width="50" align="center">
                            <template slot-scope="{ row, $index }">
                                <i v-if="row.TakeStatus == 0" class="el-icon-delete" @click="delSpecRow(row, $index)" />
                            </template>
                        </el-table-column>
                    </el-table>
                    <el-descriptions v-show="detailTab == 'proxy'" class="proxy-table" :column="2" border>
                        <template v-if="detail.Proxy">
                            <el-descriptions-item label="订单号" :span="2">
                                {{ detail.Proxy.Tid }} <el-button type="text" @click="copyOther(detail.Proxy.Tid, $event)">复制</el-button>
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label">
                                    <i class="el-icon-user"></i>
                                    收件人
                                </template>{{ detail.Proxy.ReceiverName }}
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label">
                                    <i class="el-icon-mobile-phone"></i>
                                    手机号
                                </template>
                                {{ detail.Proxy.ReceiverMobile }} <el-button type="text"
                                    @click="copyOther(detail.Proxy.ReceiverMobile, $event)">复制</el-button>
                            </el-descriptions-item>
                            <el-descriptions-item :span="2">
                                <template slot="label">
                                    <i class="el-icon-location-outline"></i>
                                    收货地址
                                </template>
                                {{ detail.Proxy.ReceiverProvince }} {{ detail.Proxy.ReceiverCity }} {{
                                    detail.Proxy.ReceiverDistrict }} {{ detail.Proxy.ReceiverAddress }}
                            </el-descriptions-item>
                            <el-descriptions-item label="运费">￥
                                <el-input :disabled="!param.onTrades.some(t => t.TakeStatus < 2)" style="width: 70px;" v-model="detail.Proxy.PostFee"
                                    @change="editProxy('postfee')" v-format:float></el-input>
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label">
                                    <el-popover placement="bottom-start" width="270" trigger="hover">
                                        <el-input style="margin-bottom: 5px;" type="textarea" v-model="proxy.express" :rows="4" />
                                        <el-button type="primary" style="width: 100%;" ref="sendExpress"
                                            @click="handleProxySend(proxy.express, $event)">{{ detail.Sender &&
                                                detail.Sender.BotUid && detail.Sender.SenderUid ? '发送' : '复制' }}</el-button>
                                        <el-button type="primary" style="margin:0 5px;" slot="reference">物流通知</el-button>
                                    </el-popover>
                                </template>
                            </el-descriptions-item>
                            <el-descriptions-item label="快递" :span="2">
                                快递：<el-input style="width: calc(40% - 40px);" v-model="detail.Proxy.ExpressName" placeholder="快递名称"
                                    @change="editProxy()"></el-input>
                                单号：
                                <el-input style="width: calc(60% - 70px);;" v-model.number="detail.Proxy.ExpressNo" @change="editProxy()"></el-input>
                                <el-button type="text" @click="copyOther(detail.Proxy.ExpressNo, $event)">复制</el-button>
                            </el-descriptions-item>
                        </template>
                    </el-descriptions>
                </el-main>
                <el-footer height="105px">
                    <el-container class="statistics">
                        <el-main>
                            <div>
                                <span class="purchaser" :title="detail.PurchaserName">{{ detail.PurchaserName }}</span>
                            </div>
                            <div>
                                <span style="font-weight: normal;">
                                    {{ (detail.CreateTime || '').substring(2, 16) }}{{ detail.CreateTime ? '报单' : '' }}
                                </span>
                            </div>
                            <div v-if="status != 'waitTake'">
                                <span>
                                    {{ (detail.TakeTime || '').substring(2, 16) }}{{ detail.TakeTime ? '提货' : '' }}
                                </span>
                            </div>
                        </el-main>
                        <el-aside width="25%">
                            <div>
                                <span>应收<span class="money"
                                        :title="detail.Payment + (detail.DiscountFee && detail.DiscountFee != 0 ? `（优惠￥${detail.DiscountFee}）` : '')">
                                        ￥{{ detail.Payment | decimalFormat }}<span v-if="detail.DiscountFee && detail.DiscountFee != 0">
                                            (-{{ detail.DiscountFee | decimalFormat }})</span></span></span>
                            </div>
                            <div>
                                <span>已收<span class="money" :title="detail.ReceivedPayment">
                                        ￥{{ detail.ReceivedPayment | decimalFormat }}</span></span>
                            </div>
                            <div>
                                <span>待收<span class="money" :title="detail.RestPayment">
                                        ￥{{ detail.RestPayment | decimalFormat }}</span></span>
                            </div>
                        </el-aside>
                        <el-aside width="15%">
                            <div>
                                <span>报单</span>
                            </div>
                            <div>
                                <span :title="detail.Num || 0">{{ detail.Num || 0 }}</span>双
                            </div>
                            <div>
                                <span :title="detail.TotalFee">￥{{ detail.TotalFee | decimalFormat }}</span>
                            </div>
                        </el-aside>
                        <el-aside width="15%">
                            <div><span>配货</span></div>
                            <div>
                                <span :title="detail.TakeNum || 0">{{ detail.TakeNum || 0 }}</span>双
                            </div>
                            <div>
                                <span :title="detail.TakeFee">￥{{ detail.TakeFee | decimalFormat }}</span>
                            </div>
                        </el-aside>
                        <el-aside width="15%">
                            <div><span>{{ param.onTrades.length == 1 && detail.Proxy ? '运费' : '退货' }}</span></div>
                            <div v-if="param.onTrades.length == 1 && detail.Proxy" style="height:31px;">
                            </div>
                            <div v-else>
                                <span :title="detail.RefundNum || 0">{{ detail.RefundNum || 0 }}</span>双
                            </div>
                            <div>
                                <span :title="detail.RefundFee"> ￥{{ (param.onTrades.length == 1 && detail.Proxy ?
                                    detail.PostFee : detail.RefundFee) | decimalFormat }}</span>
                            </div>
                        </el-aside>
                    </el-container>
                </el-footer>
                <el-footer height="58px" v-show="detail.PurchaserInfo && detail.PurchaserInfo.DebtFee && detail.PurchaserInfo.DebtFee != 0">
                    <el-container class="dept">
                        <el-aside class="title all" width="46px">累计<br />欠款</el-aside>
                        <el-main class="money all">
                            <span :title="detail.PurchaserInfo.DebtFee">{{ detail.PurchaserInfo.DebtFee | decimalFormat
                                }}</span>
                        </el-main>
                        <el-aside class="title" width="40px">往期<br />欠款</el-aside>
                        <el-aside class="money" width="12%">
                            <span :title="detail.PurchaserInfo.PastDebtFee">{{ detail.PurchaserInfo.PastDebtFee |
                                decimalFormat }}</span>
                        </el-aside>
                        <el-aside class="title" width="41px">今日<br />提货</el-aside>
                        <el-aside class="money" width="12%">
                            <span :title="detail.PurchaserInfo.TodayTakeFee - detail.PurchaserInfo.TodayRefundFee">{{
                                detail.PurchaserInfo.TodayTakeFee - detail.PurchaserInfo.TodayRefundFee | decimalFormat
                            }}</span>
                        </el-aside>
                        <el-aside class="desc" width="14%">
                            <div :title="detail.PurchaserInfo.TodayTakeFee">
                                <span>提 {{ detail.PurchaserInfo.TodayTakeFee | decimalFormat }}</span>
                            </div>
                            <div :title="detail.PurchaserInfo.TodayRefundFee">
                                <span>退 {{ detail.PurchaserInfo.TodayRefundFee | decimalFormat }}</span>
                            </div>
                        </el-aside>
                        <el-aside class="title" width="41px">今日<br />还款</el-aside>
                        <el-aside class="money" width="12%">
                            <span :title="detail.PurchaserInfo.TodayRePayment">{{ detail.PurchaserInfo.TodayRePayment |
                                decimalFormat }}</span>
                        </el-aside>
                        <el-aside class="money" width="50px" v-if="source != 'bill'">
                            <el-button style="padding: 5px; font-size: 15px;" type="success" @click="showRepayDlg">还款</el-button>
                        </el-aside>
                    </el-container>
                </el-footer>
            </el-container>
        </el-main>
        <el-footer height="43px">
            <el-container class="remark">
                <el-main>
                    <el-input placeholder="请输入备注" v-model="detail.Remark">
                        <template slot="prepend">备注</template>
                        <el-button slot="append" v-if="source != 'bill'" @click="editRemark"
                            style="background-color: #409eff;color: white;">保存</el-button>
                    </el-input>
                </el-main>
                <el-aside width="285px" style="text-align: right;">
                    <span class="el-icon-s-tools" @click="$refs.printConfig.showDialog()" title="打印设置"
                        style="color: #409eff;padding-right: 18px;"></span>
                    <el-button type="primary" plain @click="print">打印小票</el-button>
                    <el-button type="primary" plain @click="copy">复制粘贴</el-button>
                    <el-popover placement="top-start" title="请选择截图方式" width="100" trigger="hover" style="margin-left: 10px;"
                        v-if="detail.Sender && detail.Sender.BotUid && detail.Sender.SenderUid">
                        <p>
                            <span>接单QQ：{{ detail.Sender.BotUid }}</span><br />
                            <span>客户名：{{ detail.Sender.SenderName }}</span><br />
                            <span>客户QQ：{{ detail.Sender.SenderUid }}</span>
                        </p>
                        <el-button type="warning" style="padding:9px;" @click="createImage('copy')">复制截图</el-button>
                        <el-button type="success" style="padding:9px;" @click="createImage('send')">发送截图</el-button>
                        <el-button type="primary" plain slot="reference">截图</el-button>
                    </el-popover>
                    <el-button v-else type="primary" plain @click="createImage('copy')">截图</el-button>
                </el-aside>
            </el-container>
        </el-footer>
        <el-footer height="130px" v-if="source != 'bill' && detail.ItemDetails" style="padding:5px">
            <div class="operate2">
                <el-row :gutter="5">
                    <el-col :span="16">
                        <el-input class="receive" v-model="detail.ReceivePayment" v-format.float>
                            <template slot="prepend">实<br />收</template>
                        </el-input>
                    </el-col>
                    <el-col :span="4">
                        <template v-if="status == 'waitTake'">
                            <el-button type="danger" @click="take('credit')">欠款<br />{{ detail.Proxy ? '发货' : ''
                                }}</el-button>
                            <el-button type="info" :disabled="param.onTrades.some(t => t.TakeStatus == 1)" plain @click="doRecall(0)">取消<br />{{
                                detail.Proxy ? '发' : '提' }}货</el-button>
                            <el-button type="warning" plain @click="take('cash')">现金<br />{{ detail.Proxy ? '发货'
                                : '' }}</el-button>
                        </template>
                        <template v-else>
                            <el-button type="danger" v-show="onTradeIds.length > 0"
                                :disabled="param.onTrades.some(t => (t.RefundIDs != '' && t.TradeIDs == '') || (t.TakeStatus == 1))"
                                @click="doRecall(1)">撤回</el-button>
                            <el-button type="danger" v-show="onTradeIds.length == 0 && onRefundIds.length > 0" @click="doRecall(2)">删除</el-button>
                            <el-button type="warning" :disabled="!detail.ReceivePayment" @click="repayTrade('cash')">现金</el-button>
                        </template>
                    </el-col>
                    <el-col :span="4">
                        <template v-if="status == 'waitTake'">
                            <el-button type="primary" @click="take('alipay')">支付宝<br />{{ detail.Proxy ? '发货' :
                                '' }}</el-button>
                            <el-button type="success" @click="take('wechat')">微信<br />{{ detail.Proxy ? '发货' :
                                '' }}</el-button>
                        </template>
                        <template v-else>
                            <el-button type="primary" :disabled="!detail.ReceivePayment" @click="repayTrade('alipay')">支付宝</el-button>
                            <el-button type="success" :disabled="!detail.ReceivePayment" @click="repayTrade('wechat')">微信</el-button>
                        </template>
                    </el-col>
                </el-row>
            </div>
        </el-footer>
        <edit-num ref="editNumDlg" @handleSave="editSave"></edit-num>
        <cashier-repay ref="repayDlg" @refresh="refresh"></cashier-repay>
        <product-setting ref="productSettingDlg" @handleSearch="getDetails()" />
        <add-refund ref="addRefund" @handleAddRefundItems="getDetails"></add-refund>
        <edit-out-stock ref="editOutStock" @handleSuccess="getDetails()"></edit-out-stock>
        <stall-config ref="printConfig" :field.sync="configField" :value.sync="printConfig" :dialog="true" @handleSave="$refs.tradePrint.init(false)"
            view-tab="bill" :level="0" source="cashier" width="600px"></stall-config>
        <div id="print-view">
            <div class="print-view-main">
                <div class="paper-wrapper paper-bill"></div>
            </div>
            <trade-print ref="tradePrint" print-view-tab="bill" :print-config.sync="printConfig" print-source="cashier"></trade-print>
        </div>
        <el-dialog v-dialog-drag title="请选择差额处理方式" width="500px" :visible="discountVisible" :close-on-click-modal="false"
            @close="discountVisible = false">
            <span class="money">当前您“实收金额”与“应收金额”，不一致!</span><br />
            <span>差额：￥<span class="money">{{ detail.RestPayment - detail.ReceivePayment | decimalFormat
                    }}</span>，应收金额：￥{{ detail.RestPayment | decimalFormat }}，实收金额：￥{{ detail.ReceivePayment |
                        decimalFormat }}</span>
            <div slot="footer" class="dialog-footer">
                <el-button size="small" @click="discountVisible = false">取消</el-button>
                <el-button type="primary" plain size="small" @click="doTake(true)">优惠</el-button>
                <el-button type="primary" size="small" @click="doTake()">欠款</el-button>
            </div>
        </el-dialog>
        <el-dialog v-dialog-drag :width="printConfig.bill.checkStockBySpec ? '700px' : '600px'" :visible="outStockForm.visible"
            :close-on-click-modal="false" @close="outStockForm.visible = false">
            <template slot="title">
                <div class="stock-title">
                    <div>缺货登记</div>
                    <div class="item-title">
                        货号：<span class="point">{{ outStockForm.row.ItemNo }}</span>，合计 <span class="point">{{
                            outStockForm.row.Num }}</span> 件，出库 <span class="point" style="color:red;">{{
                                outStockForm.row.editNum }}</span> 件
                    </div>
                    <el-switch style="text-align:right;" v-model="printConfig.bill.checkStockBySpec" @change="$refs.printConfig.saveConfig();"
                        active-text="关联规格">
                    </el-switch>
                </div>
            </template>
            <div v-if="printConfig.bill.checkStockBySpec == false">
                出库数量：<el-input placeholder="请输入出库数量" @change="updateDetailNum" v-model="outStockForm.row.editNum" style="width:130px;" v-format.int />
            </div>
            <div v-if="printConfig.bill.checkStockBySpec == true" class="out-stock">
                <el-tag v-for="detail in outStockForm.details" :key="detail.SpecID" type="primary" size="medium" effect="light">
                    <el-tooltip effect="dark" :content="detail.SpecName + '*' + detail.Num" v-if="(detail.SpecName + '*' + detail.Num).length > 9"
                        placement="top-start">
                        <span>{{ detail.SpecName }}*{{ detail.Num }}</span>
                    </el-tooltip>
                    <span v-else>{{ detail.SpecName }}*{{ detail.Num }}</span>
                    <el-input v-model="detail.editNum" @change="updateItemNum(detail)" v-format.int></el-input>
                </el-tag>
            </div>
            <div slot="footer" class="dialog-footer">
                <span style="float:left;margin-left:10px" v-show="printConfig.bill.checkStockBySpec == true">批量修改：
                    <el-input placeholder="请输入出库数量" v-model="outStockForm.batchEditNum" @input="batchEditNumChange" style="width:130px;"
                        v-format.int /></span>
                <el-button size="small" @click="outStockForm.visible = false">取消</el-button>
                <el-button type="primary" size="small" @click="saveOutStock">保存</el-button>
            </div>
        </el-dialog>
        <el-dialog v-dialog-drag title="提示" :visible="deleteDlg.visible" width="450px" @close="deleteDlg.visible = false">
            <div class="el-message-box__content">
                <div class="el-message-box__container">
                    <div class="el-message-box__status el-icon-warning"></div>
                    <div class="el-message-box__message">
                        <p>确定要{{ deleteDlg.typeName }}吗？</p>
                    </div>
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button class="dlg_btn" @click="deleteDlg.visible = false">取消</el-button>
                <el-button class="dlg_btn" type="primary" v-if="deleteDlg.showCopyBtn" @click="executeRecall(true, $event)">复制并{{
                    deleteDlg.typeName }}</el-button>
                <el-button class="dlg_btn" type="danger" @click="executeRecall(false)">{{
                    deleteDlg.typeName }}</el-button>
            </span>
        </el-dialog>
    </el-container>
</template>

<script>
import { submit } from '@/api/base';
import clipboard from '@/utils/clipboard';
import EditNum from './EditNum.vue';
import CashierRepay from './cashierRepay.vue';
import ProductSetting from "@/views/product/simple/components/ProductSetting.vue";
import AddRefund from './AddRefund.vue';
import TradePrint from '@/views/stall/trade/components/TradePrint.vue';
import StallConfig from '@/views/stall/trade/components/StallConfig.vue';
import { floatFormat } from "@/utils/validate";
import { screenshotToBase64, copyImage, botSend } from '@/utils/stallBot';
import EditOutStock from './EditOutStock.vue';

export default {
    name: 'cashierDetail',
    props: {
        storeID: {
            type: Number,
            default: 0,
        },
        source: {
            type: String
        },
        status: {
            type: String,
            required: true
        },
        dateRange: {},
    },
    components: {
        EditNum,
        ProductSetting,
        AddRefund,
        TradePrint,
        StallConfig,
        CashierRepay,
        EditOutStock
    },
    data() {
        return {
            param: {
                onTrades: [],
                debtInfo: {}
            },
            onTradeIds: [],
            onRefundIds: [],
            detailTab: 'item',
            searchItemNo: '',
            detail: { PurchaserInfo: {} },
            detailJson: '',
            configField: {},
            printConfig: { bill: {} },
            cashFlag: false,
            discountVisible: false,
            cashPayType: null,
            saveFlag: false,
            outStockForm: {
                visible: false,
                row: {},
                batchEditNum: '',
                details: []
            },
            proxy: {
                express: '',
                expressNotice: null,
            },
            deleteDlg: {
                visible: false,
                showCopyBtn: false,
                params: null,
                typeName: '',
            }
        };
    },
    mounted() {
        this.$nextTick(async () => {
            await this.$refs.printConfig.getConfig();
            this.$refs.tradePrint.init();
        });
    },
    methods: {
        fetchData(param) {
            this.param = param;
            this.onTradeIds = [];
            this.onRefundIds = [];
            this.param.onTrades.forEach(trade => {
                if (trade.TradeIDs) {
                    this.onTradeIds.push(trade.TradeIDs);
                }
                if (trade.RefundIDs) {
                    this.onRefundIds.push(trade.RefundIDs);
                }
            });
            this.getDetails();
        },
        resetData() {
            this.detail = { PurchaserInfo: {} };
        },
        filterDetails() {
            if (!this.detail.Details) {
                return;
            }
            for (let i = 0; i < this.detail.ItemDetails.length; i++) {
                if (this.searchItemNo) {
                    if (this.detail.ItemDetails[i].ItemNo.toLowerCase().includes(this.searchItemNo.toLowerCase())) {
                        this.detail.ItemDetails[i].isHidden = false;
                    }
                    else {
                        this.detail.ItemDetails[i].isHidden = true;
                    }
                }
                else {
                    this.detail.ItemDetails[i].isHidden = false;
                }
            }
            this.detail.ItemDetails.splice(0, 0);
            for (let i = 0; i < this.detail.Details.length; i++) {
                if (this.searchItemNo) {
                    if (this.detail.Details[i].ItemNo.toLowerCase().includes(this.searchItemNo.toLowerCase())) {
                        this.detail.Details[i].isHidden = false;
                    }
                    else {
                        this.detail.Details[i].isHidden = true;
                    }
                }
                else {
                    this.detail.Details[i].isHidden = false;
                }
            }
            this.detail.Details.splice(0, 0);
        },
        async getDetails() {
            this.detail = { PurchaserInfo: {} };
            if (this.onTradeIds.length == 0 && this.onRefundIds.length == 0) {
                return;
            }
            let start = this.dateRange && this.dateRange.length > 0 ? this.dateRange[0] : null;
            let end = this.dateRange && this.dateRange.length > 1 ? this.dateRange[1] : null;
            const { data } = await submit('/api/stallCashier/getDetails', {
                startTime: start,
                endTime: end,
                IsGetPurchaser: this.source != 'bill',
                tradeIds: this.onTradeIds.join(','),
                refundIds: this.onRefundIds.join(',')
            });
            this.detail = data;
            if (this.status == 'taked') {
                this.detail.ReceivePayment = this.detail.DebtFee;
            }
            if (this.param.debtInfo) {
                this.detail.PurchaserInfo = this.param.debtInfo;
            }
            this.detail.PurchaserName = (this.param.onTrades[0].PurchaserNick ? `(${this.param.onTrades[0].PurchaserNick})` : '') + this.param.onTrades[0].PurchaserName;
            this.detail.CreateTime = this.param.onTrades[0].CreateTime;
            this.detail.TakeTime = this.param.onTrades[0].TakeTime;
            for (let detail of this.detail.Details) {
                detail.Selected = detail.TakeStatus < 2 ? detail.TakeNum != 0 : detail.TakedNum != 0;
                detail.LastTakeNum = detail.TakeNum;
            }
            this.filterDetails();
            this.$nextTick(() => {
                for (let detail of this.detail.Details) {
                    if (detail.Selected) {
                        this.$refs.specTable.toggleRowSelection(detail, true);
                    }
                }
            });
            this.detailJson = JSON.stringify(this.detail);
            if (this.param.onTrades.length == 1) {
                this.$emit('refreshTrade', {
                    TakeNum: this.detail.TakeNum,
                    RefundNum: this.detail.RefundNum,
                    RestPayment: this.detail.RestPayment,
                    ReceivedPayment: this.detail.ReceivedPayment
                });
            }
            this.setProxyContent();
        },
        getRowClass({ row }) {
            let classNames = ['detail-row'];
            if (row.Type == 'refund') {
                classNames.push('refund');
            }
            if (this.status == 'waitTake' && row.Selected) {
                classNames.push('is-active');
            }
            if (row.FlagDel) {
                classNames.push('is-delete');
            }
            if (row.isHidden) {
                classNames.push('is-hidden');
            }
            return classNames.join(' ');
        },
        selectSpecRow(selection, row) {
            row.Selected = !row.Selected;
            row.TakeNum = row.Selected ? row.Num - row.OutStockNum - row.TakedNum : 0;
            this.detail.RestPayment = floatFormat(this.detail.RestPayment - row.LastTakeNum * row.SettlePrice + row.TakeNum * row.SettlePrice);
            this.detail.ReceivePayment = floatFormat(this.detail.ReceivePayment - row.LastTakeNum * row.SettlePrice + row.TakeNum * row.SettlePrice);
            row.LastTakeNum = row.TakeNum;
        },
        selectAllSpecRow(selection) {
            const select = selection.filter((s) => s.Type != 'refund').length > 0;
            for (let row of this.detail.Details) {
                if (row.Type == "refund" || row.TakeStatus == 2) {
                    continue;
                }
                if (row.Selected == select) {
                    continue;
                }
                this.selectSpecRow(selection, row);
            }
        },
        showOutStockDlg(row) {
            this.outStockForm.batchEditNum = '';
            this.outStockForm.row = { ...row, editNum: 0 };
            this.outStockForm.row.Num = 0;
            this.outStockForm.row.OutStockNum = 0;
            let details = this.detail.Details.filter(t => t.ItemNo == row.ItemNo && t.Type == 'take');
            this.outStockForm.details = [];
            details.forEach(detail => {
                let row = { ...detail, editNum: detail.Num - detail.OutStockNum };
                this.outStockForm.row.Num += row.Num;
                this.outStockForm.row.OutStockNum += row.OutStockNum;
                this.outStockForm.row.editNum += row.editNum;
                this.outStockForm.details.push(row);
            });
            this.outStockForm.visible = true;
        },
        batchEditNumChange(val) {
            if (!val) {
                return;
            }
            this.outStockForm.details.forEach(detail => {
                detail.editNum = val;
                this.updateItemNum(detail);
            });
        },
        updateDetailNum() {
            this.outStockForm.row.OutStockNum = this.outStockForm.row.Num - this.outStockForm.row.editNum;
            let outNum = this.outStockForm.row.OutStockNum;
            this.outStockForm.details.forEach(detail => {
                detail.OutStockNum = outNum > detail.Num ? detail.Num : outNum;
                detail.editNum = detail.Num - detail.OutStockNum;
                outNum -= detail.OutStockNum;
            });
        },
        updateItemNum(detail) {
            this.outStockForm.row.OutStockNum -= detail.OutStockNum;
            detail.OutStockNum = detail.Num - detail.editNum;
            this.outStockForm.row.OutStockNum += detail.OutStockNum;
            this.outStockForm.row.editNum = this.outStockForm.row.Num - this.outStockForm.row.OutStockNum;
        },
        saveOutStock() {
            this.outStockForm.details.forEach(row => {
                let detail = this.detail.Details.filter(t => t.ProductID == row.ProductID && t.SpecID == row.SpecID);
                if (detail.length > 0) {
                    detail[0].OutStockNum = row.OutStockNum;
                }
            });
            this.saveSpecChange('修改');
            this.outStockForm.visible = false;
        },
        showEditNum(row, column, rowIndex) {
            var purchaserId = this.param.onTrades[0].PurchaserID;
            this.$nextTick(() => {
                let form = {
                    keys: [{ "label": "货号", "value": row.ItemNo }],
                    type: 'OutNum',
                    label: row.Type == 'refund' ? '退货量' : '出库量',
                    oldValue: row.Num - row.OutStockNum,
                    value: row.Num - row.OutStockNum,
                    isSubNum: row.Type == 'refund',
                    dataIndex: rowIndex,
                    otherValue: purchaserId + '|' + row.ProductID + '|' + row.SpecID
                };
                if (row.SpecName) {
                    form.keys.push({ "label": "规格", "value": row.SpecName });
                }
                this.$refs.editNumDlg.init(form);
            });
        },
        editSave(data) {
            let row = this.detail.Details[data.dataIndex];
            if (row.Type != 'refund' && data.value < row.TakedNum) {
                this.$baseMessage('此商品已出库' + row.TakedNum + '，请撤回后再扣减', 'error');
                return;
            }
            if (row.Type == 'refund' && data.value > row.TakedNum) {
                this.$baseMessage('此商品已退货' + -row.TakedNum + '，不可小于已退货量', 'error');
                return;
            }
            if (row.Type != 'refund') {
                row.OutStockNum = row.Num - data.value;
            }
            else {
                row.Num = data.value;
                row.TakeNum = data.value;
            }
            this.saveSpecChange();
        },
        //页面价格操作
        showEditPrice(row, type) {
            let postData = {
                ItemNo: row.ItemNo,
                ProductID: row.ProductID,
                TradeIDs: this.param.onTrades[0].TradeIDs ? this.param.onTrades[0].TradeIDs : this.param.onTrades[0].RefundIDs,
                PurchaserID: this.param.onTrades[0].PurchaserID,
                PurchaserName: this.param.onTrades[0].PurchaserName,
                IncludeSettle: true,
                Type: type
            };
            this.$refs.productSettingDlg.open(postData);
        },
        editTakeNum(row) {
            if (row.Type == 'refund') {
                row.TakeNum = row.LastTakeNum;
                return;
            }
            let takeNum = parseInt(row.TakeNum + '');
            if (!takeNum) {
                row.TakeNum = 0;
            }
            if (row.TakeNum == row.LastTakeNum) {
                return;
            }
            const originTakeNum = row.Num - row.OutStockNum - row.TakedNum;
            if (row.TakeNum <= 0) {
                row.TakeNum = 0;
            } else if (row.TakeNum > originTakeNum) {
                row.TakeNum = originTakeNum;
            }
            row.Selected = row.TakeNum > 0;
            this.$refs.specTable.toggleRowSelection(row, row.Selected);
            this.detail.RestPayment = floatFormat(this.detail.RestPayment - row.LastTakeNum * row.SettlePrice + row.TakeNum * row.SettlePrice);
            this.detail.ReceivePayment = floatFormat(this.detail.ReceivePayment - row.LastTakeNum * row.SettlePrice + row.TakeNum * row.SettlePrice);
            row.LastTakeNum = row.TakeNum;
        },
        async editRefundPrice(row, type = 'item') {
            if (type == 'item') {
                this.detail.Details.forEach(item => {
                    if (item.Type == 'refund' && item.ProductID == row.ProductID) {
                        item.SettlePrice = row.SettlePrice;
                    }
                });
            }
            this.saveSpecChange();
        },
        setProxyContent() {
            if (this.detail.Proxy) {
                this.proxy.express = `【快递通知】\r\n` +
                    `代发订单：${this.detail.Proxy.ReceiverName} ${this.detail.Proxy.ReceiverMobile} \r\n` +
                    `${this.detail.Proxy.ExpressName ? '快递名称：' + this.detail.Proxy.ExpressName + '\r\n' : ''}` +
                    `快递单号：${this.detail.Proxy.ExpressNo}`;
            }
        },
        async editProxy(type = 'express') {
            if (type == 'postfee' && this.detail.PostFee == this.detail.Proxy.PostFee) {
                return;
            }
            this.saveFlag = true;
            let { data } = await submit("/api/stallCashier/changeProxy", this.detail.Proxy);
            this.saveFlag = false;
            if (data) {
                this.$baseMessage(data, "error");
            }
            if (type == 'postfee') {
                this.getDetails();
            }
            if (type == 'express') {
                this.setProxyContent();
            }
        },
        delSpecRow(row) {
            if (row.TakeStatus != 0) {
                this.$baseMessage(`该规格已${row.TakeStatus == 1 ? '部分' : ''}${row.Type == 'refund' ? '退货' : '提货'}，不可删除`, 'error');
                return;
            }
            this.$baseConfirm('确定删除该规格吗？', null, async () => {
                row.FlagDel = true;
                this.saveSpecChange('删除');
            });
        },
        async showAddRefund() {
            let purchaser = null;
            if (this.param.onTrades.length == 0) {
                this.$baseMessage('请先勾选需要退货的客户，否则无法点击退货', 'error');
                return;
            }
            purchaser = { PurchaserID: this.param.onTrades[0].PurchaserID, PurchaserName: this.param.onTrades[0].PurchaserName };
            this.addRefundVisible = true;
            this.$nextTick(() => {
                let tradeId = parseInt(this.onTradeIds[0].split(',')[0]);
                let storeId = typeof (this.storeID) != 'undefined' ? this.storeID : null;
                this.$refs.addRefund.init(purchaser, tradeId, storeId);
            });
        },
        async showOnekeyOutStock(){
            let storeId = typeof (this.storeID) != 'undefined' ? this.storeID : null;
            let details = this.detail.Details.filter(x=>x.Type != 'refund');
            this.$refs.editOutStock.init(storeId, details);
        },
        async saveSpecChange(operateType = '') {
            if (!this.detailJson) {
                return;
            }
            if (this.saveFlag) {
                this.$baseMessage("保存中", "error");
                return;
            }
            if (this.detailJson == JSON.stringify(this.detail)) {
                this.$baseMessage('数据未变动', 'error');
                return;
            }
            this.saveFlag = true;
            let storeId = typeof (this.storeID) != 'undefined' ? this.storeID : null;
            let { data } = await submit("/api/stallCashier/save", {
                details: this.detail.Details,
                storeID: storeId,
            });
            this.saveFlag = false;
            if (data) {
                this.$baseMessage(data, "error");
            }
            else if (operateType) {
                this.$baseMessage(operateType + "成功", "success");
            }
            this.getDetails();
        },
        async editRemark() {
            if (this.param.onTrades.length == 0) {
                this.$baseMessage('请选择要保存备注的数据', 'error');
                return;
            }
            if (!this.detail.Remark) {
                this.$baseMessage('备注不能为空', 'error');
                return;
            }
            await submit('/api/stallCashier/UpdateCashRemark', {
                tradeIDs: this.onTradeIds[0],
                refundIDs: this.onRefundIds[0],
                remark: this.detail.Remark,
            });
            this.$baseMessage('保存成功', 'success');
        },
        async print() {
            if (this.onTradeIds.length == 0 && this.onRefundIds.length == 0) {
                this.$baseMessage('请选择要打印的数据', 'error');
                return;
            }
            const tradeIds = this.onTradeIds.join(',');
            const refundIds = this.onRefundIds.join(',');
            let takeStatus = null;
            if (this.printConfig.bill.apartByTakeStatus && this.source != 'proxy') {
                takeStatus = this.status == 'waitTake' ? 0 : 1;
            }
            await this.$refs.tradePrint.getPrintInfo(tradeIds, refundIds, null, null, takeStatus);
            if (this.$refs.tradePrint.printData.length > 0) {
                await this.$refs.tradePrint.print(tradeIds, 5, refundIds);
                this.$('#print-view .paper-bill,#print-view .paper-tag').empty();
            }
        },
        async copy(e) {
            if (this.param.onTrades.length == 0) {
                this.$baseMessage('请选择要复制的数据', 'error');
                return;
            }
            let spiltChar = '\t';
            let details = this.detail.Details.filter(detail => { return detail.Selected });
            let detailGroup = this._.groupBy(details, 'ItemNo');
            let detailText = '';
            let refundText = '';
            for (let item in detailGroup) {
                let num = 0, payment = 0, refundNum = 0, refundFee = 0;
                for (let detail of detailGroup[item]) {
                    if (detail.Type == 'refund') {
                        refundNum += detail.Num;
                        refundFee += detail.Payment;
                    }
                    else {
                        num += detail.Num - detail.OutStockNum;
                        payment += detail.Payment;
                    }
                }
                if (num != 0) {
                    detailText += `${detailGroup[item][0].ItemNo}${spiltChar}总数：${num}${spiltChar}合计：${floatFormat(payment)}\r\n`;
                }
                if (refundNum != 0) {
                    refundText += `【退】${detailGroup[item][0].ItemNo}${spiltChar}总数：${refundNum}${spiltChar}合计：${floatFormat(refundFee)}\r\n`;
                }
            }
            let text = this.param.onTrades[0].PurchaserName + '\r\n\r\n';
            text += detailText;
            text += refundText;
            text += '\r\n';
            text += `报单数：${this.detail.Num}\r\n`;
            text += `缺货数：${this.detail.Num - this.detail.TakeNum}\r\n`;
            text += `配货数：${this.detail.TakeNum}\r\n`;
            text += `退货数：${this.detail.RefundNum}\r\n`;
            text += '\r\n';
            text += `合计：${floatFormat(this.detail.Payment)}\r\n`;
            text += `已收：${floatFormat(this.detail.ReceivedPayment)}\r\n`;
            text += `待收：${floatFormat(this.detail.RestPayment)}\r\n`;
            if (this.printConfig.bill.showDebtFee == true && this.detail.PurchaserInfo.DebtFee != 0) {
                text += `累计欠款：${floatFormat(this.detail.PurchaserInfo.DebtFee)}\r\n`;
            }
            clipboard(text, e);
        },
        async copyOther(content, e) {
            clipboard(content, e);
        },
        async take(payType) {
            if (!this.param.onTrades.some(t => t.TakeStatus < 2)) {
                return;
            }
            if (this.detail.Proxy) {
                if (!this.detail.Proxy.ExpressNo) {
                    this.$baseMessage('请填写快递单号', 'error');
                    return;
                }
            }
            if (this.onTradeIds.length == 0) {
                this.$baseMessage('请选择要处理的数据', 'error');
                return;
            }
            if (this.detail.Details.filter((detail) => detail.Type == 'take' && detail.Selected).length == 0
                && this.detail.Details.filter((detail) => detail.Type == 'take' && detail.Num - detail.OutStockNum - detail.TakedNum > 0).length > 0) {
                this.$baseMessage('请选择要处理的明细', 'error');
                return;
            }
            if (this.detail.RestPayment != this.detail.ReceivePayment) {
                if (this.detail.RestPayment > 0) {
                    if (this.detail.ReceivePayment < 0) {
                        this.$baseMessage('实收金额不能小于0', 'error');
                        return;
                    }
                    if (this.detail.RestPayment < this.detail.ReceivePayment) {
                        this.$baseMessage('实收金额不能大于待收金额', 'error');
                        return;
                    }
                }
                if (this.detail.RestPayment < 0) {
                    if (this.detail.ReceivePayment > 0) {
                        this.$baseMessage('实收金额不能大于0', 'error');
                        return;
                    }
                    if (this.detail.RestPayment > this.detail.ReceivePayment) {
                        this.$baseMessage('实收金额不能小于待收金额', 'error');
                        return;
                    }
                }
                this.cashPayType = payType;
                this.discountVisible = true;
                return;
            }
            this.cashPayType = payType;
            await this.doTake();
        },
        async doTake(isDiscount = false) {
            if (this.cashFlag) {
                this.$baseMessage("提货处理中，请稍后", "error");
                return;
            }
            this.cashFlag = true;
            let { data } = await submit("/api/stallCashier/take", {
                IsDiscount: isDiscount,
                restPayment: this.detail.RestPayment,
                receivePayment: this.detail.ReceivePayment,
                remark: this.detail.Remark,
                payType: this.cashPayType,
                details: this.detail.Details,
            });
            this.cashFlag = false;
            if (data) {
                this.$baseMessage(data, "error");
                return;
            }
            this.cashPayType = null;
            this.discountVisible = false;
            this.$baseMessage("成功", "success");
            this.$emit('refresh');
        },
        showRepayDlg() {
            if (!this.detail.PurchaserInfo || !this.detail.PurchaserInfo.DebtFee) {
                this.$baseMessage('请先选择客户！', 'error');
                return;
            }
            if (this.detail.PurchaserInfo.DebtFee == 0) {
                this.$baseMessage('欠款为0，无需还款', 'error');
                return;
            }
            let repayParam = {
                IsRepayTrade: false,
                PurchaserID: this.detail.PurchaserInfo.PurchaserID,
                PurchaserName: this.param.onTrades[0].PurchaserName,
                PurchaserNick: this.param.onTrades[0].PurchaserNick,
                DebtFee: this.detail.PurchaserInfo.DebtFee,
                ReceivePayment: '',
            };
            this.$refs.repayDlg.show(repayParam);
        },
        async repayTrade(payType) {
            if (this.param.onTrades.length == 0) {
                this.$baseMessage('请先选择订单！', 'error');
                return;
            }
            if (!this.param.onTrades.some(t => t.PayStatus < 2)) {
                this.$baseMessage('订单已还款！', 'error');
                return;
            }
            let repayParam = {
                IsRepayTrade: true,
                TradeIds: this.onTradeIds.join(','),
                RefundIds: this.onRefundIds.join(','),
                DebtFee: this.detail.DebtFee,
                ReceivePayment: this.detail.ReceivePayment
            };
            this.$refs.repayDlg.repay(payType, repayParam);
        },
        async doRecall(type, isSubmit) {
            let name;
            switch (type) {
                default:
                    name = '取消提货'
                    break;
                case 1:
                    name = '撤回结算';
                    break;
                case 2:
                    name = '删除';
                    break;
            }
            if (this.onTradeIds.length == 0 && type != 2) {
                this.$baseMessage(`请选择要${name}的数据`, 'error');
                return;
            }
            if (this.onRefundIds.length == 0 && type == 2) {
                this.$baseMessage(`请选择要删除的数据`, 'error');
                return;
            }
            if (type == 0 && this.detail.Details.filter((detail) => detail.TakeStatus > 1).length > 0) {
                this.$baseMessage('当前报单存在已提货商品，无法取消提货', 'error');
                return;
            }
            let _this = this;
            this.deleteDlg.typeName = name;
            this.deleteDlg.params = {
                TradeIds: _this.onTradeIds.join(','),
                RefundIds: _this.onRefundIds.join(','),
                Type: type
            };
            this.deleteDlg.showCopyBtn = type == 2;
            this.deleteDlg.visible = true;
        },
        async executeRecall(isCopy, e) {
            if (isCopy) {
                var content = this.param.onTrades[0].PurchaserName + '\r\n';
                this.detail.Details.forEach(x => {
                    content += x.ItemNo + (x.SpecName ? ' ' : '') + x.SpecName + '*' + Math.abs(x.TakedNum) + '\r\n';
                });
                clipboard(content, e);
            }
            await submit('/api/stallCashier/recall', this.deleteDlg.params);
            this.$baseMessage(`${this.deleteDlg.typeName}成功`, 'success');
            this.$emit('refresh');
            this.deleteDlg.visible = false;
        },
        async createImage(type) {
            if (this.onTradeIds.length == 0 && this.onRefundIds.length == 0) {
                this.$baseMessage('请选择要截图的数据', 'error');
                return;
            }
            //#region 获取需要转换为图像的DOM元素
            let element = document.getElementById('detail-main');
            let copyElement = element.cloneNode(true);
            copyElement.style.height = 'auto';
            copyElement.style.zIndex = -1;
            copyElement.style.right = -610;
            copyElement.style.position = 'absolute';
            while (copyElement.firstChild.nodeType == 8) {
                copyElement.removeChild(copyElement.firstChild);
            }

            copyElement.firstChild.style.height = 'auto';
            copyElement.firstChild.firstChild.style.height = 'auto';
            copyElement.firstChild.style.minHeight = "360px";
            copyElement.firstChild.firstChild.children[2].style.height = 'auto';
            copyElement.firstChild.children[1].children[2].style.height = 'auto';

            var elTables = copyElement.getElementsByClassName('el-table');
            for (let i = 0; i < elTables.length; i++) {
                if (elTables[i].style.display == 'none') {
                    continue;
                }
                var priceColumns = elTables[i].getElementsByClassName('column_price');
                if (priceColumns.length > 0) {
                    let column_idx = -1;
                    for (let i = priceColumns.length - 1; i >= 0; i--) {
                        let element = priceColumns[i];
                        if (column_idx < 0) {
                            column_idx = Array.prototype.indexOf.call(element.parentNode.getElementsByTagName(element.tagName), element);
                        }
                        priceColumns[i].remove();
                    }
                    if (column_idx > -1) {
                        let colgroups = elTables[i].getElementsByTagName('colgroup');
                        for (let i = 0; i < colgroups.length; i++) {
                            let cols = colgroups[i].getElementsByTagName('col');
                            if (cols.length >= column_idx) {
                                cols[column_idx].remove();
                            }
                        }
                    }
                }
            }


            element.parentNode.appendChild(copyElement); // 添加到body
            //#endregion

            let dataUrl = await screenshotToBase64(copyElement);
            copyElement.remove();
            if (type == "copy") {
                copyImage(dataUrl);
            }
            else {
                let res = await botSend("img", dataUrl, {
                    botType: this.detail.Sender.BotType,
                    botUid: this.detail.Sender.BotUid,
                    senderUid: this.detail.Sender.SenderUid
                });
                if (res) {
                    this.$message({ type: 'success', message: '发送成功' });
                }
            }
        },
        async handleProxySend(content, e) {
            if (!content) {
                return;
            }
            if (this.detail.Sender && this.detail.Sender.BotUid && this.detail.Sender.SenderUid) {
                const res = await submit('/api/stallBot/send', {
                    BotUid: this.detail.Sender.BotUid,
                    SenderUid: this.detail.Sender.SenderUid,
                    Content: content
                });
                if (res.data) {
                    this.$baseMessage(res.data, "error");
                    return;
                }
                this.$message({ type: "success", message: `通知发送成功!` });
            }
            else {
                clipboard(content, e);
                this.$baseMessage('复制通知成功，已复制到粘贴板', 'success');
            }
        },
        refresh() {
            this.$emit('refresh');
        }
    },
};
</script>

<style lang="scss" scoped>
#detail-main {
    ::v-deep {
        .el-input__inner {
            text-align: center;
        }
    }
}

.detail-main {
    height: 100%;
    border-radius: 7px;
    position: relative;
    overflow-y: hidden;

    .proxy-table {
        height: 100%;
    }

    ::v-deep {
        .detail-row {
            cursor: pointer;

            td {
                padding: 5px 0;
            }

            .el-icon-edit-outline {
                color: #409eff !important;
            }

            .el-icon-delete {
                color: #ff0000 !important;
            }

            &.refund {
                .cell {
                    color: #ff0000 !important;
                }
            }

            &.is-active {
                td {
                    background: #e9f4ff !important;
                }
            }

            &.is-delete,
            &.is-hidden {
                display: none;
            }
        }
    }

    .operate1 {
        box-sizing: border-box;
        text-align: right;
        position: absolute;
        top: 0px;
        right: 2px;
    }

    .statistics {
        background-color: #d5eaff !important;

        &>main,
        &>aside {
            padding: 0 5px;

            &:first-of-type {
                &>div {
                    text-align: left;
                }
            }

            &>div {
                line-height: 34px;
                text-align: center;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;

                &>span {
                    font-weight: bold;
                    font-size: 18px;
                    line-height: 31px;
                }
            }
        }

        &>main {
            &>div {
                line-height: 32px;
            }
        }

        .money {
            color: red;
        }

        .purchaser {
            font-size: 30px;
        }
    }

    .dept {
        background-color: #abd4fd !important;
        display: flex;
        align-items: center;
        justify-content: center;

        .title {
            font-size: 15px;
            border: 0;
            line-height: 24px;
        }

        .title.all {
            font-weight: bold;
            font-size: 18px;
            color: black;
        }

        .money {
            color: #0075e3;
            font-size: 20px;
            overflow: hidden;
            text-overflow: ellipsis;
            word-break: break-all;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            max-height: 57px;

            &.all {
                font-size: 24px;
                line-height: 24px;
                color: red;
                border: 0;
            }
        }


        .desc {
            color: #8d8a90;
            font-size: 10px;
            max-width: 55%;

            &>div {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }
    }

    .remark {

        &>main,
        &>aside {
            border: 0;
        }

        ::v-deep {
            .el-input-group__prepend {
                width: 30px;
            }
        }

    }

    .operate2 {
        .receive {

            ::v-deep {
                .el-input-group__prepend {
                    width: 30px;
                }

                .el-input__inner {
                    height: 120px;
                    color: red;
                    font-size: 45px;
                    font-weight: bold;
                }

                .el-input-group__prepend {
                    font-size: 30px;
                    color: black;
                    font-weight: bold;
                    text-align: center;
                }
            }
        }

        .el-row {
            .el-col {
                height: 120px;
                position: relative;

                .el-tag {
                    margin-left: 0;
                    margin-bottom: 5px;
                    width: calc(100%);
                    height: calc(50% - 2.5px);
                    padding: 15px 0;
                    font-weight: bold;
                    font-size: 16px;
                    cursor: pointer;
                    text-align: center;

                    &--danger {
                        margin-left: 5px;
                        width: calc(60% - 2.5px);
                    }

                    &--warning {
                        margin-left: 5px;
                        width: calc(50% - 2.5px);
                    }

                    &--dark {
                        position: relative;

                        &::before {
                            content: "\e6da";
                            font-family: element-icons !important;
                            position: absolute;
                            bottom: 0;
                            right: 0;
                        }
                    }
                }

                .el-button {
                    display: inline-block;
                    vertical-align: top;
                    margin-bottom: 5px;
                    margin-left: 0;
                    width: calc(100%);
                    height: calc(50% - 2.5px);
                    padding: 0;
                    font-weight: bold;
                    font-size: 20px;

                    &--default {
                        width: calc(40% - 2.5px);
                    }

                    &--info,
                    &--warning.is-plain {
                        width: calc(50% - 2.5px);
                        font-weight: normal;
                        font-size: 10px;
                    }

                    &--warning.is-plain {
                        margin-left: 5px;
                    }
                }

                .delivery {
                    width: calc(100%);
                    height: calc(100%);
                    font-weight: bold;
                    font-size: 20px;
                }
            }
        }

        &>div {
            vertical-align: top;
        }


        .box1 {
            vertical-align: top;
            width: calc(61.5%);
            display: inline-block;
        }

        .box1_1 {
            width: calc(79.5%);
        }

        .box2 {
            display: inline-block;
            height: 120px;
            width: calc(36%);

            .el-button {
                vertical-align: top;
                margin-bottom: 5px;
                margin-left: 0px;
                width: calc(100%);
                max-width: 101px;
                height: calc(50% - 5px);
                font-weight: bold;
                font-size: 20px;
            }

            &>div {
                vertical-align: top;
                display: inline-block;
                width: calc(50% - 5px);
                padding-left: 5px;
                height: 100%;

                ::v-deep {
                    .el-button--info.is-plain {
                        width: calc(50% - 2.5px);
                        line-height: 20px;
                        margin-right: 2.5px;
                        padding: 0px;
                        font-weight: normal;
                        font-size: 10px;
                    }

                    .el-button--warning.is-plain {
                        width: calc(50% - 2.5px);
                        margin-left: 2.5px;
                        line-height: 20px;
                        padding: 0px;
                        font-weight: normal;
                        font-size: 10px;
                    }
                }
            }

            .repay {
                height: calc(100% - 5px);
                font-size: 30px;
            }
        }
    }

    #print-view {
        position: fixed;
        left: -1000px;
    }

    .stock-title {
        width: calc(100% - 40px);

        &>div {
            display: inline-block;
            min-width: 25%;
        }

        .item-title {
            text-align: center;
            width: calc(50%);

            .point {
                font-size: 20px;
                font-family: 'fangsong', Courier, monospace;
                font-weight: bold;
            }
        }
    }

    ::v-deep {

        .dlg_btn {
            width: 80px;
            padding: 10px 10px;
        }

        .el-dialog__body {
            .money {
                font-size: 18px;
                font-family: 'fangsong', Courier, monospace;
                font-weight: bold;
                color: red;
            }

            .el-form {
                padding-right: 0px;
            }

            .el-form-item {

                .el-input {
                    max-width: 150px;
                }

                .el-date-editor--date {
                    width: 150px !important;
                }

                .el-button {
                    margin-left: 2px;
                }

                .el-form-item__label {
                    padding: 0 2px;
                }
            }

            .out-stock {

                .el-input {
                    width: 40px;
                    vertical-align: top;

                    .el-input__inner {
                        padding: 0;
                        text-align: center;
                    }
                }

                .el-tag--medium {
                    height: 42px;
                    line-height: 42px;
                    font-size: 15px;
                    margin: 0 10px 5px 0;
                    width: calc(25% - 10px);

                    &>span {
                        display: inline-block;
                        width: calc(100% - 33px);
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow-x: hidden;
                    }
                }
            }

        }
    }
}
</style>