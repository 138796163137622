<template>
  <el-container class="cash-register-main">
    <!--中间内容区域-->
    <el-main>
      <el-container class="trade-main">
        <el-header v-if="$store.state.routes.systemSetting.MultiStore" height="40px">
          <div>
            <el-radio-group v-model="queryParam.storeID" @input="changeStore">
              <el-radio-button v-for="item in (stores || [])" :key="item.ID" :label="item.ID">{{ item.Name
                }}</el-radio-button>
            </el-radio-group>
          </div>
        </el-header>
        <el-header height="40px">
          <el-tabs :value="queryParam.status" type="card" @tab-click="changeTab" :disabled="listLoading == true">
            <el-tab-pane :label="queryParam.source == 'proxy' ? '待发货订单' : '待提货客户'" name="waitTake"></el-tab-pane>
            <el-tab-pane :label="queryParam.source == 'proxy' ? '已发货订单' : '已提货客户'" name="taked"></el-tab-pane>
          </el-tabs>
          <el-form :inline="true">
            <el-form-item>
              <el-tooltip effect="dark" content="关闭：隐私模式，根据条码展示订单" placement="top-start">
                <i :class="encryptView ? 'ri-eye-close-line' : 'ri-eye-line'" @click="changeEncrypt()"
                  style="font-size: 30px; font-weight: normal;color: #409EFF;" />
              </el-tooltip>
            </el-form-item>
            <template v-if="!encryptView">
              <el-form-item>
                <el-date-picker v-model="dateRange" type="daterange" range-separator="至" start-placeholde="开始日期" end-placeholde="结束日期"
                  value-format="yyyy-MM-dd" class="date-range" @change="getList()" :clearable="queryParam.status == 'waitTake'"
                  clear-icon="el-icon-error" :picker-options="datePickerOptions"></el-date-picker>
              </el-form-item>
              <el-form-item>
                <template slot="label">
                  查找<el-tooltip effect="dark" content="查找范围：客户名称、客户昵称、货号" placement="top-start">
                    <i class="el-icon-question" />
                  </el-tooltip>：
                </template>
                <el-input v-model="queryParam.searchValue" :title="queryParam.searchValue" :class="{ 'search-item': true }"
                  @keydown.enter.native="getList()">
                </el-input>
              </el-form-item>
              <el-form-item label="待收：">
                <el-input v-model="queryParam.receivePayment" placeholder="待收金额" :class="{ 'search-item': true }" @keydown.enter.native="getList()"
                  v-format.float></el-input>
              </el-form-item>
            </template>
            <el-form-item label="条码：">
              <el-input v-model="queryParam.barcode" :title="queryParam.barcode" ref="barcode" :class="{ 'barcode': true, 'encrypt': encryptView }"
                @keydown.enter.native="getList()" clearable>
              </el-input>
            </el-form-item>
            <el-form-item v-if="queryParam.source != 'proxy'">
              <el-tooltip effect="dark" content="合并" placement="top-start">
                <el-switch v-model="queryParam.isMerge" @change="getList()"></el-switch>
              </el-tooltip>
            </el-form-item>
            <el-form-item>
              <el-button-group>
                <el-button type="primary" @click="getList()">查询</el-button>
                <el-button plain @click="resetQuery">清空</el-button>
              </el-button-group>
            </el-form-item>
            <el-form-item style="float: right;">
              <el-button type="danger" @click="showAddRefund">仅退货</el-button>
            </el-form-item>
          </el-form>
        </el-header>
        <el-main style="padding:10px 5px 0 5px">
          <div class="trade-list" v-loading="listLoading">
            <el-card v-for="(trade, index) in trades || [] " v-show="!trade.Hidden" shadow="hover"
              :key="queryParam.source + '-' + trade.TradeIDs + '-' + index" @click.native="selectTrade(trade)"
              :class="{ trade: true, 'is-active': trade.Selected, multi: trade.TradeIDs.indexOf(',') > -1 || trade.RefundIDs.indexOf(',') > -1, proxy: trade.IsProxy }">
              <div slot="header" :class="getPurchaserClass(trade)" :title="trade.PurchaserName">
                {{ trade.Num > trade.TakeNum ? '[缺]' : '' }}
                {{ (trade.PurchaserNick ? `(${trade.PurchaserNick})` : '') + trade.PurchaserName }}
              </div>
              <div class="take-num" :title="trade.TakeNum - trade.RefundNum + '双'">
                件数：{{ trade.TakeNum - trade.RefundNum }}件</div>
              <div class="payment" :title="(trade.PayStatus < 2 ? trade.ReceivePayment : trade.ReceivedPayment) + '元'">
                {{ trade.PayStatus > 0 ? '已收：' + trade.ReceivedPayment : '待收：' + trade.ReceivePayment
                }}元</div>
              <div class="create-time">{{ (queryParam.status == 'waitTake' ? trade.CreateTime :
                trade.TakeTime).substring(2, 16) }}</div>
            </el-card>
            <el-empty v-show="trades.length == 0" description="暂无数据"></el-empty>
          </div>
        </el-main>
        <el-footer height="40px" style="padding-right:10px">
          <template v-if="queryParam.source != 'proxy'">
            <el-switch style="margin-right:15px;" v-model="exportSpec" active-text="导出规格">
            </el-switch>
            <el-switch style="margin-right:15px;" v-model="exportIsMerge" active-text="导出合并数据">
            </el-switch>
            <el-button type="primary" plain @click="exportTrade()">导出订单</el-button>
          </template>
          <el-popover v-else placement="top-start" width="220" trigger="click" title="选择导出内容：">
            <el-button type="text" @click="exportTrade()">导出全部信息</el-button>
            <el-button type="text" @click="exportTrade('express')">导出快递信息</el-button>
            <el-button type="primary" plain slot="reference">导出订单</el-button>
          </el-popover>
          <el-upload ref="uploadXls" accept=".xls,.xlxs" :action="xlsData.uploadUrl" :show-file-list="false" :headers="xlsData.headers"
            :on-success="uploadXlsSuccess" :multiple="false" :on-error="uploadXlsError" v-if="queryParam.source == 'proxy'">
            <el-button type="warning" plain>导入快递</el-button>
          </el-upload>
          <span>客户：{{ statistics.purchaserIDs.length }}</span>
          <span v-if="queryParam.status == 'waitTake'">待收：{{ statistics.receivePayment | decimalFormat }}</span>
          <span v-else>已收：{{ statistics.receivedPayment | decimalFormat }}</span>
        </el-footer>
      </el-container>
      <div class="pay-type" v-if="queryParam.status == 'taked'">
        <div :class="payTypes.includes('wechat') ? 'is-active' : ''" @click="filterPayType('wechat')">
          <span class="wechat"><img src="/static/img/wechat.png" /></span>已转微信
        </div>
        <div :class="payTypes.includes('alipay') ? 'is-active' : ''" @click="filterPayType('alipay')">
          <span class="alipay"><img src="/static/img/alipay.png" /></span>已转支付宝
        </div>
        <div :class="payTypes.includes('cash') ? 'is-active' : ''" @click="filterPayType('cash')">
          <span class="cash"><img src="/static/img/cash.png" /></span>已转现金
        </div>
        <div :class="payTypes.includes('credit') ? 'is-active' : ''" @click="filterPayType('credit')">
          <span class="credit"><img src="/static/img/credit.png" /></span>欠款
        </div>
        <div :class="payTypes.includes('part') ? 'is-active' : ''" @click="filterPayType('part')" v-if="queryParam.source != 'proxy'">
          <span class="part-take"><img src="/static/img/part.png" /></span>货未提完
        </div>
        <div :class="payTypes.includes('refund') ? 'is-active' : ''" @click="filterPayType('refund')" v-if="queryParam.source != 'proxy'">
          <span class="refund"><img src="/static/img/refund.png" /></span>有退货
        </div>
      </div>
    </el-main>
    <el-aside width="610px">
      <cashier-detail ref="detail" :source="queryParam.source" :dateRange="dateRange" :status="queryParam.status" :storeID="queryParam.storeID"
        @refreshTrade="refreshTrade" @refresh="getList(true)" />
    </el-aside>
    <add-refund ref="addRefund" @handleAddSuccess="getList()"></add-refund>
    <helper-center ref="helperCenter" position="cashier" :visible="true"></helper-center>
  </el-container>
</template>

<script>
import { submit, exportFile } from '@/api/base';
import dayjs from 'dayjs';
import { baseURL } from "@/config";
import { getDate, getDatePickerOptions } from '@/utils/tool';
import CashierDetail from '@/views/stall/trade/cashier/components/cashierDetail.vue';
import AddRefund from '@/views/stall/trade/cashier/components/AddRefund.vue';
import HelperCenter from '@/views/stall/trade/components/HelperCenter.vue';

export default {
  name: 'stallCashierIndex',
  watch: {
    $route: {
      handler: 'setPageQuery',
    }
  },
  created() {
    this.setPageQuery();
  },
  components: {
    AddRefund,
    CashierDetail,
    HelperCenter
  },
  data() {
    return {
      listLoading: false,
      otherDateRange: '',
      dateRange: '',
      datePickerOptions: getDatePickerOptions(),
      encryptView: false,
      stores: [],
      queryParam: {
        source: '',
        storeID: 0,
        status: 'waitTake',
        startTime: null,
        endTime: null,
        searchValue: null,
        receivePayment: null,
        barcode: null,
        isMerge: true
      },
      exportIsMerge: true,
      exportSpec: true,
      trades: [],
      onTrades: [],
      statistics: {
        total: 0,
        purchaserIDs: [],
        receivePayment: 0,
        receivedPayment: 0
      },
      payTypes: [],
      xlsData: {
        loading: false,
        uploadUrl: `${baseURL}/api/stall/proxy/importExpress`,
        headers: {
          Authorization: "Bearer " + this.$store.getters["user/accessToken"],
        },
      },
    };
  },
  mounted() {
    this.$nextTick(async () => {
      if (this.$store.state.routes.systemSetting.MultiStore) {
        await this.getStores();
      }
      const today = getDate();
      this.dateDebtRange = [today, today];
      this.otherDateRange = [today, today];
      this.dateRange = [today, today];
      this.getList();
      this.$refs.barcode.focus();
    });
  },
  methods: {
    changeStore() {
      this.getList();
    },
    async getStores() {
      const { data } = await submit("/api/common/getStoreInfoList");
      if (typeof (data) != 'undefined' && data.length > 0) {
        this.stores = data;
        let findItem = data.find(x => x.DefaultFlag);
        if (findItem) {
          this.queryParam.storeID = findItem.ID;
        }
      }
    },
    setPageQuery() {
      let arr = this.$route.fullPath.split('/');
      this.queryParam.status = 'waitTake';
      if (['trade', 'proxy'].some(t => t == arr[arr.length - 1])) {
        this.queryParam.source = arr[arr.length - 1];
        if (this.queryParam.source == 'proxy') {
          this.queryParam.isMerge = false;
        }
        else {
          this.queryParam.isMerge = true;
        }
      }
    },
    resetQuery() {
      this.queryParam.searchValue = null;
      this.queryParam.receivePayment = null;
      this.getList();
    },
    initData() {
      this.trades = [];
      this.$refs.detail.resetData();
      this.onTrades = [];
      this.statistics = {
        total: 0,
        purchaserIDs: [],
        receivePayment: 0,
        receivedPayment: 0
      };
    },
    getPurchaserClass(trade) {
      let classes = ['purchaser'];
      if (trade.Num > trade.TakeNum) {
        classes.push('out-stock-num');
      }
      if (trade.IsProxy) {
        classes.push('proxy');
      }
      if (trade.RefundNum > 0) {
        classes.push('refund');
      }
      else if (trade.TakeStatus == 1) {
        classes.push('split');
      }
      if (this.queryParam.status == 'taked') {
        classes.push(this.getPayType(trade));
      }
      return classes.join(' ');
    },
    changeTab(tab) {
      if (tab.name == this.queryParam.status) {
        return;
      }
      this.queryParam.status = tab.name;
      let tempDateRange = this.dateRange;
      this.dateRange = this.otherDateRange;
      this.otherDateRange = tempDateRange;
      this.getList();
    },
    changeEncrypt() {
      this.encryptView = !this.encryptView;
      this.getList();
    },
    getQueryParam() {
      let param = { ...this.queryParam };
      if (this.encryptView) {
        param.startTime = null;
        param.endTime = null;
        param.searchValue = null;
        param.receivePayment = null;
      }
      else {
        param.startTime = this.dateRange && this.dateRange.length > 0 ? this.dateRange[0] : null;
        param.endTime = this.dateRange && this.dateRange.length > 1 ? this.dateRange[1] : null;
        param.barcode = null;
      }
      return param;
    },
    async getList(keepSelect = false) {
      let selectTrades = keepSelect && this.onTrades.length > 0 ? this.onTrades : null;
      this.initData();
      if (this.encryptView && !this.queryParam.barcode) {
        this.$nextTick(async () => {
          this.$refs.barcode.focus();
        });
        return;
      }
      this.listLoading = true;
      const { data } = await submit('/api/stallCashier/getList', this.getQueryParam());
      this.listLoading = false;
      this.trades = data;
      this.trades.forEach(d => {
        if (!this.statistics.purchaserIDs.some(t => t == d.PurchaserID)) {
          this.statistics.purchaserIDs.push(d.PurchaserID);
        }
        this.statistics.receivePayment += d.ReceivePayment;
        this.statistics.receivedPayment += d.ReceivedPayment;
      });
      if (this.encryptView) {
        if (this.trades.length > 0) {
          this.selectTrade(this.trades[0]);
        }
      } else {
        this.keepSelectTrade(selectTrades);
      }
    },
    keepSelectTrade(selectTrades = null) {
      let tempTrade = null;
      for (let trade of this.trades) {
        trade.LastPayType = this.getPayType(trade);
        if (this.queryParam.status == 'taked') {
          trade.Hidden = this.payTypes.length > 0;
          for (let type of this.payTypes) {
            if ((type == 'part' && trade.TakeStatus == 1) || (type == 'refund' && trade.RefundNum != 0) || (trade.PayType.includes(type))) {
              trade.Hidden = false;
              break;
            }
          }
        }
        trade.Selected = false;
        if (trade.Hidden) {
          continue;
        }
        if (selectTrades && selectTrades.some(t => t.TradeIDs == trade.TradeIDs)) {
          tempTrade = trade;
        }
      }
      if (tempTrade) {
        this.selectTrade(tempTrade);
      }
    },
    selectTrade(selectTrade) {
      this.onTrades = [];
      selectTrade.Selected = !selectTrade.Selected;
      for (let trade of this.trades) {
        if (trade.PurchaserID != selectTrade.PurchaserID) {
          trade.Selected = false;
        }
        else if (this.queryParam.source == 'proxy' && selectTrade.TradeIDs != trade.TradeIDs) {
          trade.Selected = false;
        }

        if (trade.Selected) {
          this.onTrades.push(trade);
        }
      }
      this.$forceUpdate();
      this.getDetails();
    },
    getPayType(trade) {
      if (trade.PayType && trade.PayType.includes('credit')) {
        return 'credit';
      }
      let payTypes = trade.PayType ? trade.PayType.split(',') : [];
      return payTypes.length > 0 ? payTypes[payTypes.length - 1] : '';
    },
    filterPayType(payType) {
      let select = true;
      for (let i = 0; i < this.payTypes.length; i++) {
        if (this.payTypes[i] == payType) {
          select = false;
          this.payTypes.splice(i, 1);
          break;
        }
      }
      if (select) {
        this.payTypes.push(payType);
      }
      this.keepSelectTrade(this.onTrades ? this.onTrades : null);
      this.$forceUpdate();
    },
    refreshTrade(data) {
      this.onTrades[0].TakeNum = data.TakeNum;
      this.onTrades[0].RefundNum = data.RefundNum;
      this.onTrades[0].ReceivePayment = data.RestPayment;
      this.onTrades[0].ReceivedPayment = data.ReceivedPayment;
    },
    async getDetails() {
      this.$refs.detail.fetchData({
        onTrades: this.onTrades,
        debtInfo: null
      });
    },
    async showAddRefund() {
      this.$refs.addRefund.init(null, 0, this.$store.state.routes.systemSetting.MultiStore ? this.queryParam.storeID : null);
    },
    async exportTrade(type = 'all') {
      var param = {
        ...this.getQueryParam(),
        Type: type,
        ExportIsMerge: this.exportIsMerge,
        exportSpec: this.exportSpec,
      };
      const { data } = await exportFile('/api/stallCashier/export', param);
      let date = '';
      let today = dayjs().format('YYYY-MM-DD');
      let start = param.startTime ? dayjs(param.startTime).format('YYYY-MM-DD') : '';
      let end = param.endTime ? dayjs(param.endTime).format('YYYY-MM-DD') : '';
      if (param.startTime && param.endTime) {
        date = start == end ? start : start + '至' + end;
      } else if (param.startTime) {
        date = start + '至' + today;
      } else if (param.endTime) {
        date = today + '至' + end;
      }
      const fileName = this.queryParam.source == 'proxy' ? (type == 'all' ? `${date}订单表` : `${date}订单快递表`) : `店铺收银导出${date}`;

      const blob = new Blob([data.Blob], { type: "application/vnd.ms-excel" });
      var a = document.createElement("a");
      a.href = URL.createObjectURL(blob);
      a.download = fileName;
      a.style.display = "none";
      document.body.appendChild(a);
      a.click();
      a.remove();
    },
    uploadXlsSuccess(res) {
      if (res.msg) {
        this.$message({ type: "error", message: "导入错误：" + res.msg });
      } else {
        this.$message({ type: "success", message: "导入成功，" + res.data });
      }
      this.$refs.uploadXls.clearFiles();
      this.xlsData.loading = false;
      this.getList();
    },
    uploadXlsError() {
      this.$message({ type: "error", message: "导入失败" });
      this.xlsData.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@mixin trade-icon {
  position: absolute;
  height: 28px;
  width: 28px;
  border-radius: 14px;
  opacity: 0.9;

  img {
    height: 16px;
    width: 16px;
  }
}

.cash-register-main {
  min-width: 1760px;

  .trade-main {
    height: 100%;
    border-radius: 7px;

    &>header {
      position: relative;

      .el-form {
        position: absolute;
        left: 230px;
        top: 0;
        width: calc(100% - 230px);

        .el-select {
          width: 100px;
          margin-right: 5px;
        }

        .date-range {
          width: 210px;

          ::v-deep {
            .el-range-input {
              width: 70px;
            }
          }
        }

        .search-item {
          width: 85px !important;
        }

        .barcode {
          width: 45px !important;
          margin-right: 5px;

          &.encrypt {
            width: 200px !important;
            max-width: 250px;
          }
        }

        .el-button {
          width: 68px;
        }

        ::v-deep {
          .el-range-editor {
            &.el-input__inner {
              padding: 3px 0 0 5px;
            }

            .el-range-separator {
              width: auto;
            }

            .el-range__close-icon {
              width: 5px;
            }
          }

          .el-form-item__label {
            padding-right: 0;
          }

          .el-form-item {
            margin-right: 8px;
          }

          .el-input__inner {
            padding: 3px 0 0 5px;
          }
        }
      }

      ::v-deep {
        .el-radio-button__inner {
          font-size: 14px;
        }
      }
    }

    .trade-list {
      height: 100%;
      overflow-y: auto;
      position: relative;

      .trade {
        display: inline-block;
        width: 120px;
        height: 105px;
        font-size: 14px;
        font-weight: bold;
        border-radius: 5px;
        color: #303133;
        cursor: pointer;
        background-color: #f3f7f9;
        border: solid 2px #d2dadf;
        position: relative;
        overflow: hidden;
        margin: 0 5px 5px 0;

        &.is-active {
          background-color: #e9f4ff;
          border-color: #409eff;
        }

        &.multi {
          &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin: 1px;
            border-right: solid 1px #d2dadf;
            border-bottom: solid 1px #d2dadf;
          }

          &::after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin: 4px;
            border-right: solid 1px #d2dadf;
            border-bottom: solid 1px #d2dadf;
          }
        }

        ::v-deep {

          .el-card__header,
          .el-card__body {
            padding: 5px;
            position: relative;

            &>div {
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              box-sizing: border-box;
              min-height: 20px;
            }
          }
        }

        .purchaser {
          text-align: center;

          &.out-stock-num {
            color: #f44a4a;
          }

          &::before {
            content: "";
            position: absolute;
            box-sizing: border-box;
            top: 0px;
            left: 0px;
            width: 20px;
            height: 20px;
            background-size: 20px;
            background-position: center;
            background-repeat: no-repeat;
            border-radius: 10px;
          }

          &::after {
            content: "";
            position: absolute;
            box-sizing: border-box;
            top: 0px;
            right: 0px;
            width: 20px;
            height: 20px;
            background-size: 16px;
            background-position: center;
            background-repeat: no-repeat;
            border-radius: 10px;
          }

          &.proxy {
            &::before {
              content: '代';
              color: #fff;
              background-color: #0075E3;
              width: 22px;
              height: 22px;
            }
          }

          &.split {
            &::before {
              background-image: url('/static/img/part.png');
            }
          }

          &.refund {
            &::before {
              background-image: url('/static/img/refund.png');
            }
          }

          &.credit {
            &::after {
              background-color: #f44a4a;
              background-image: url('/static/img/credit.png');
            }
          }

          &.alipay {
            &::after {
              background-color: #409eff;
              background-image: url('/static/img/alipay.png');
            }
          }

          &.wechat {
            &::after {
              background-color: #67c23a;
              background-image: url('/static/img/wechat.png');
            }
          }

          &.cash {
            &::after {
              background-color: #e6a23c;
              background-image: url('/static/img/cash.png');
            }
          }

        }

        .create-time {
          font-weight: normal;
        }
      }

      .trade.is-active {
        background-color: #e9f4ff;
        border-color: #409eff;
      }
    }

    &>footer {
      text-align: right;
      font-size: 18px;
      font-weight: bold;
      line-height: 40px;

      &>div {
        display: inline-flex;
        vertical-align: middle;
        margin-left: 10px;
      }

      &>span {
        margin-left: 20px;
        display: inline-flex;
        vertical-align: middle;
      }

      &>i {
        margin-left: 20px;
        vertical-align: middle;
      }
    }
  }

  main {
    position: relative;
    overflow-x: hidden;

    .pay-type {
      position: absolute;
      top: 90px;
      right: -72px;

      &:hover {
        right: 0px;
      }

      div {
        border: 1px solid #409eff;
        border-radius: 20px;
        border-right: none;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        padding: 3px 3px 3px 5px;
        cursor: pointer;
        background: #fff;

        &.is-active {
          background: #dbedff;
        }
      }

      div:not(:first-child) {
        border-top: none;
      }

      span {
        height: 25px;
        width: 25px;
        display: inline-block;
        border-radius: 15px;
        vertical-align: middle;
        text-align: center;
        margin-right: 3px;

        &.wechat {
          background: #67c23a;
        }

        &.alipay {
          background: #409eff;
        }

        &.cash {
          background: #e6a23c;
        }

        &.credit {
          background: #f44a4a;
        }

        &.part-take {
          border: 1px solid #6c57f0;
        }

        &.refund {
          border: 1px solid #f44a4a;
        }
      }

      img {
        display: inline-block;
        height: 20px;
        width: 20px;
        margin-top: 3px;
      }
    }

  }

}
</style>