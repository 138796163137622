<template>
  <el-container>
    <el-aside width="720px">
      <el-container>
        <el-header height="80px">
          <el-form :inline="true">
            <el-form-item label="采购商：">
              <el-input v-model="queryParam.SearchValue" placeholder="请输入采购商" style="width: 140px"></el-input>
            </el-form-item>
            <el-form-item>
              <el-select v-model="queryParam.QQType" style="width: 100px">
                <el-option value="QQ" label="QQ"></el-option>
                <el-option value="BotQQ" label="接单QQ"></el-option>
              </el-select>
              <el-input v-model="queryParam.QQValue" placeholder="请输入QQ号" style="width: 140px"></el-input>
            </el-form-item>
            <el-form-item label="货号：">
              <el-input v-model="queryParam.ItemNo" placeholder="请输入货号" style="width: 140px"></el-input>
            </el-form-item>
            <el-form-item style="float:right;">
              <el-button-group>
                <el-button type="primary" @click="handleQuery()">查询</el-button>
                <el-button type="default" @click="reset()">重置</el-button>
              </el-button-group>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" icon="el-icon-plus" @click="handleAdd">新增</el-button>
              <el-button type="danger" icon="el-icon-delete" @click="handleDelete(null)">删除</el-button>
              <el-button type="primary" plain icon="el-icon-download" @click="handleExport"
                :loading="exportLoading">导出</el-button>
            </el-form-item>
          </el-form>
        </el-header>
        <el-main>
          <grid-table ref="gridTable" :tableConfig="tableConfig" @selectionChange="handleSelectionChange"
            @handleRowClick="handleRowClick" :showLastEmptyColumn="false" :showIndexColumn="false"
            :showCheckboxColumn="true">
            <template slot="Name" slot-scope="{ params }">
              {{ params.row['Name'] }}
            </template>
            <template slot="OP" slot-scope="{ params }">
              <el-button type="text" @click.stop="handleEdit(params.row)">编辑</el-button>
              <el-button type="text" class="red" @click.stop="handleDelete(params.row)">删除</el-button>
            </template>
          </grid-table>
        </el-main>
        <customer-edit ref="editDialog" v-if="dialogVisible" @handleQuery="handleQuery"></customer-edit>
      </el-container>
    </el-aside>
    <el-main>
      <el-container>
        <el-header height="42px">
          <div>
            <span class="div_detail_name">{{ detail.row.ID > 0 ? detail.row.Name : '' }}</span>
            <span class="div_detail_title">指定价管理</span>
            <span style="float:right;">
              <el-input v-model="detailSearchItemNo" placeholder="请输入货号" style="width: 180px;margin-right:5px;"></el-input>
              <el-button type="primary" @click="handleDetailSearch">搜索</el-button>
            </span>
          </div>
        </el-header>
        <el-main>
          <el-container>
            <el-header height="90px">
              <div class="div_detail_all">
                该采购商<span class="red">所有商品</span>默认指定价比商品价便宜：￥
                <el-input-number v-model="priceDiscount" :min="0" :controls="false" :disabled="detail.row.ID <= 0"
                  placeholder="请输入" style="width: 60px;margin-right:5px;"></el-input-number>
                <el-button type="primary" @click="handlePriceDiscountChange" :disabled="detail.row.ID <= 0">设置</el-button>
                <span class="span_detail_tip">(价格优先级：黑色-36 > 全部-36 > 黑色-全部 > 全部-全部 > 规格价)</span>
              </div>
              <div class="div_detail_button">
                <el-button type="primary" @click="handleAddDetail" :disabled="detail.row.ID <= 0">新增特殊指定价</el-button>
                <el-button type="danger" @click="handleDeleteDetail(null)" :disabled="detail.row.ID <= 0">删除</el-button>
              </div>
            </el-header>
            <el-main>
              <el-table ref="detailTable" :data="detail.rowDetails" v-loading="detail.loading" border
                :row-class-name="handleRowClassName" :header-cell-style="{
                  'background-color': '#E5EEF6',
                  'border-color': 'rgba(218, 223, 228, 1)',
                  height: '48px',
                }" @selection-change="handleDetailSelectionChange" height="100%">
                <el-table-column type="selection" width="39" align="center" />
                <el-table-column prop="ItemNo" label="货号" align="center" :filters="itemNoFilters"
                  :filter-method="handleFilter" />
                <el-table-column prop="Color" label="颜色" align="center" :filters="colorFilters"
                  :filter-method="handleFilter">
                  <template slot-scope="{ row }">
                    {{ row.Color }}
                  </template>
                </el-table-column>
                <el-table-column prop="Size" label="尺码" align="center" />
                <el-table-column prop="Price" label="商品价" align="center">
                  <template slot-scope="{ row }">
                    {{ row.Price }}
                  </template>
                </el-table-column>
                <el-table-column prop="SettlePrice" label="指定价" align="center">
                  <template slot-scope="{ row }">
                    <template v-if="row.SettleType == 0">
                      <span style="color:#409eff;">固定价：</span>￥{{ row.SettlePrice }}
                    </template>
                    <template v-if="row.SettleType == 1">
                      <span style="color:#67c23a">便宜：</span>￥{{ row.SettlePrice }}<span class="span_wname">(即：{{
                        row.Price
                        -
                        row.SettlePrice
                      }})</span>
                    </template>
                  </template>
                </el-table-column>
                <el-table-column prop="OP" label="操作" width="120" align="center">
                  <template slot-scope="{ row }">
                    <el-button type="text" @click="handleEditDetail(row)">编辑</el-button>
                    <el-button type="text" class="red" @click="handleDeleteDetail(row)">删除</el-button>
                  </template>
                </el-table-column>
              </el-table>
            </el-main>
          </el-container>
        </el-main>
      </el-container>
    </el-main>
    <settle-price-edit ref="settlePriceDialog" v-if="settlePriceDialogVisible"
      @handleCallback="handleSettlePriceCallback"></settle-price-edit>
  </el-container>
</template>

<script>
import { submit, exportFile } from "@/api/base";
import dayjs from "dayjs";
import CustomerEdit from "@/views/stall/purchaser/components/Edit.vue";
import SettlePriceEdit from "@/views/stall/purchaser/components/SettlePriceEdit.vue";
export default {
  name: "customerList",
  components: {
    CustomerEdit,
    SettlePriceEdit
  },
  data() {
    return {
      routeParam: {},
      tableConfig: {
        url: "/api/stall/getPurchaserList",
        rowKey: "ID",
        columns: [
          {
            label: "采购商",
            prop: "Name",
            type: "custom-operation",
          },
          {
            label: '仓库',
            prop: 'WName',
            props: ['WName'],
            type: 'text',
            isHidden: !this.$store.state.routes.systemSetting.MultiWarehouse,
          },
          {
            label: "昵称",
            prop: "Nick",
            type: "text",
            width: "101px",
          },
          {
            label: "QQ",
            prop: "QQ",
            type: "text",
            width: "120px",
          },
          {
            label: "接单QQ",
            prop: "BotQQ",
            type: "text",
            width: "120px",
          },
          {
            label: "操作",
            prop: "OP",
            type: "custom-operation",
            width: "85px",
          },
        ],
      },
      queryParam: {
        SearchValue: '',
        QQType: 'BotQQ',
        QQValue: '',
        ItemNo: '',
      },
      itemNoFilters: [],
      colorFilters: [],
      selectRows: [],
      detailSelectRows: [],
      dialogVisible: false,
      editFormConfig: {},
      detail: {
        loading: false,
        row: { ID: 0, Name: '', PriceDiscount: 0 },
        rowDetails: [],
        rowSourceDetails: [],
      },
      settlePriceDialogVisible: false,
      priceDiscount: 0,
      detailSearchItemNo: '',
      exportLoading: false,
    };
  },
  mounted() {
    var that = this;
    that.$nextTick(() => {
      that.handleQuery();
    });
  },
  methods: {
    handleDetailSearch() {
      var that = this;
      var details = [...that.detail.rowSourceDetails];
      if (that.detailSearchItemNo != '') {
        details = details.filter(x => x.ItemNo.toUpperCase().indexOf(that.detailSearchItemNo.toUpperCase()) >= 0);
      }
      that.detail.rowDetails = details;
    },
    async handlePriceDiscountChange() {
      var that = this;
      let currentValue = that.priceDiscount;
      let oldValue = that.detail.row.PriceDiscount;
      if (that.detail.row.ID <= 0) {
        return;
      }
      let data = await submit('/api/stall/savePurchaser', {
        Purchaser: { PurchaserID: that.detail.row.PurchaserID, PriceDiscount: that.detail.row.PriceDiscount, },
        ChangeType: "PriceDiscount",
      }).catch((error) => {
        that.detail.row.PriceDiscount = oldValue;
      });
      if (!data.success) {
        that.$message({ type: 'error', message: data.msg });
        that.detail.row.PriceDiscount = oldValue;
      } else {
        that.$message({ type: 'success', message: '保存成功' });
        for (let i = 0; i < that.$refs.gridTable.tableData.length; i++) {
          let row = that.$refs.gridTable.tableData[i];
          if (row.PurchaserID == that.detail.row.PurchaserID) {
            row.Config.PriceDiscount = currentValue;
            row.PriceDiscount = currentValue;
            break;
          }
        }
      }
    },
    handleRowClassName({ row }) {
      let className = [];
      if (row.IsDelete) {
        className = 'is-deleted';
      }
      return className;
    },
    handleFilter(value, row, column) {
      const property = column['property'];
      return row[property] === value;
    },
    reset() {
      this.queryParam.ItemNo = '';
      this.queryParam.SearchValue = '';
      this.queryParam.QQValue = '';
      this.handleQuery();
    },
    removeEditData() {
      this.priceDiscount = 0;
      this.detailSearchItemNo = '';
      this.detail.row = { ID: 0, PriceDiscount: 0 };
      this.detail.rowDetails = [];
      this.detail.rowSourceDetails = [];
      this.itemNoFilters = [];
      this.colorFilters = [];
      this.$refs.detailTable.clearFilter();
      this.detail.price_discount = 0;
    },
    getConfigValue(row, field, defaultValue = '') {
      if (typeof (row.Config) != 'undefined' && typeof (row.Config[field]) != 'undefined') {
        return row.Config[field];
      }
      return defaultValue;
    },
    handleDelete(row) {
      var _this = this;//
      var idList = [];
      var confirmText = '';
      if (row == null) {
        if (!_this.selectRows || _this.selectRows.length == 0) {
          _this.$baseMessage(`请选择要删除的数据`, "error");
          return false;
        }
        _this.selectRows.forEach(t => {
          idList.push(t['ID']);
        });
        confirmText = '确定要删除选中的 ' + _this.selectRows.length + '个采购商？';
      } else {
        idList.push(row['ID']);
        confirmText = '确定要删除采购商【' + row['Name'] + '】？';
      }
      _this.$confirm(confirmText, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        beforeClose: async (action, instance, done) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true;
            instance.confirmButtonText = '正在提交中...';
            const rsp = await submit('/api/stall/deletePurchaser', { IDs: idList.join() }).catch((error) => {
              instance.confirmButtonLoading = false;
              instance.confirmButtonText = '确认';
              done();
            });
            instance.confirmButtonLoading = false;
            _this.$message({ type: rsp.success ? "success" : "error", message: rsp.msg });
            _this.handleQuery();
            done();
          } else {
            done();
          }
        }
      });
    },
    async handleRowClick(row) {
      var that = this;
      that.$refs.gridTable.$refs.gridTable.clearSelection();
      that.$refs.gridTable.$refs.gridTable.toggleRowSelection(row);
      that.detail.loading = true;
      that.removeEditData();
      const { data } = await submit(`/api/stall/getPriceDetails`, { PurchaserID: row.PurchaserID });
      that.detail.row = { ...row };
      that.detail.rowDetails = this.sortDetails(data ?? []);
      var itemNos = {};
      var colors = {};
      for (let i = 0; i < that.detail.rowDetails.length; i++) {
        var item = that.detail.rowDetails[i];
        itemNos[item.ItemNo] = 1;
        colors[item.Color] = 1;
      }
      for (let key in itemNos) {
        that.itemNoFilters.push({ text: key, value: key });
      }
      for (let key in colors) {
        that.colorFilters.push({ text: key, value: key });
      }
      that.itemNoFilters = that.itemNoFilters.sort(function (a, b) {
        if (a.ItemNo !== b.ItemNo) return a.ItemNo < b.ItemNo ? -1 : 1;
      });
      that.colorFilters = that.colorFilters.sort(function (a, b) {
        if (a.Color !== b.Color) return a.Color < b.Color ? -1 : 1;
      });
      that.priceDiscount = that.detail.row.PriceDiscount;
      that.detail.rowSourceDetails = [...that.detail.rowDetails];
      that.detail.loading = false;
      that.$nextTick(() => {
        let deletedList = that.detail.rowDetails.filter(t => t.IsDelete);
        if (deletedList.length > 0) {
          that.doDeleteDetail("检测到采购商【" + row.Name + "】的指定价列表中有 " + deletedList.length + " 条记录的商品、规格不存在或已删除，是否删除？", { PurchaserID: row.PurchaserID, NotExists: true }, row);
        }
      });
    },
    doDeleteDetail(conformText, param, row) {
      var that = this;
      that.$confirm(conformText, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        beforeClose: async (action, instance, done) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true;
            instance.confirmButtonText = '正在提交中...';
            let data = await submit('/api/stall/deletePriceDetails', param).catch((error) => {
              instance.confirmButtonLoading = false;
              instance.confirmButtonText = '确认';
              done();
            });
            that.$message({ type: data.success ? "success" : "error", message: data.msg });
            instance.confirmButtonLoading = false;
            that.handleRowClick(row);
            done();
          } else {
            done();
          }
        }
      });
    },
    sortDetails(data) {
      return data.sort(function (a, b) {
        if (a.ProductID !== b.ProductID) return a.ProductID < b.ProductID ? -1 : 1
        else if (a.Color !== b.Color) return a.Color < b.Color ? -1 : 1
        else if (a.Size !== b.Size) return a.Size < b.Size ? -1 : 1
      });
    },
    handleSettlePriceCallback() {
      this.handleRowClick(this.detail.row);
    },
    handleAddDetail() {
      var that = this;
      that.settlePriceDialogVisible = true;
      that.$nextTick(() => {
        that.$refs.settlePriceDialog.open(that.detail.row);
      });
    },
    handleEditDetail(row) {
      var that = this;
      that.settlePriceDialogVisible = true;
      that.$nextTick(() => {
        that.$refs.settlePriceDialog.open(that.detail.row, row.ItemNo);
      });
    },
    handleDeleteDetail(row) {
      var that = this;
      var ids = [];
      var conformText = "";
      if (row == null) {
        if (that.detailSelectRows.length <= 0) {
          that.$message({ type: "error", message: '请选择要删除的数据' });
          return;
        }
        that.detailSelectRows.forEach(t => {
          ids.push(t.ID);
        });
        conformText = "确定要删除选中的 " + ids.length + " 条指定价记录？";
      } else {
        ids.push(row.ID);
        conformText = "确定要删除当前指定价记录？";
      }
      that.doDeleteDetail(conformText, { PurchaserID: that.detail.row.PurchaserID, IDs: ids }, that.detail.row);
    },
    async handleExport() {
      this.exportLoading = true;
      const today = dayjs().format('YYYYMMDDHHmmss');
      var param = this.queryParam;
      const { data } = await exportFile('/api/stall/exportPurchaserList', param).finally(() => { this.exportLoading = false; });

      const blob = new Blob([data.Blob], { type: "application/vnd.ms-excel" });
      var a = document.createElement("a");
      a.href = URL.createObjectURL(blob);
      a.download = '采购商_' + today + '.xls';
      a.style.display = "none";
      document.body.appendChild(a);
      a.click();
      a.remove();
    },
    handleQuery() {
      var that = this;
      that.removeEditData();
      that.$refs.gridTable.searchParam = { ...that.queryParam };
      that.$refs.gridTable.fetchData(false, null, that.$refs.gridTable.searchParam);
    },
    handleSelectionChange(selection) {
      this.selectRows = selection;
    },
    handleDetailSelectionChange(selection) {
      this.detailSelectRows = selection;
    },
    handleAdd() {
      var _this = this;
      _this.dialogVisible = true;
      _this.$nextTick(() => {
        _this.$refs.editDialog.init('新增', null, _this.$store.state.routes.systemSetting);
      });
    },
    handleEdit(row) {
      var _this = this;
      _this.dialogVisible = true;
      _this.$nextTick(() => {
        _this.$refs.editDialog.init('编辑', row, _this.$store.state.routes.systemSetting);
      });
    },

  },
};
</script>
<style lang="scss" scoped>
::v-deep {
  .red {
    color: red;
  }

  .el-aside {
    .el-header {
      .el-form {
        .el-form-item {
          margin-bottom: 5px !important;
        }
      }

      .el-select {
        width: 100px;
        margin-right: 5px;
      }

      .el-form-item__label {
        padding-right: 0;
      }
    }
  }

  .el-table {

    .is-deleted {
      color: red;
      text-decoration: line-through;

      .cell {
        color: #b5b5b5;
      }
    }
  }

  .span_wname {
    color: #9e9e9e;
    font-size: 12px;
  }

  .div_detail_title {
    font-weight: normal;
    font-size: 24px;
    line-height: 42px;
  }

  .div_detail_name {
    font-weight: bold;
    font-size: 30px;
    line-height: 42px;
    margin-right: 10px;
  }

  .div_detail_all {
    font-size: 15px;
    height: 40px;
    line-height: 40px;
    margin: 10px 0 0 10px;

    .span_detail_tip {
      color: #9e9e9e;
      font-size: 13px;
      margin-left: 10px;
    }
  }

  .div_detail_button {
    margin-left: 10px;
  }

  .el-main {
    border-top: 0px !important;
  }
}
</style>