<template>
    <el-dialog title="退货" :visible="visible" :close-on-click-modal="false" width="1100px" @close="handleCancel">
        <template slot="title">
            <div>
                <span class="el-dialog__title">退货</span>
                <el-popover style="margin-left: 50px;" placement="bottom" trigger="click" ref="popover">
                    <el-input type="textarea" v-model="analyseContent"
                        :placeholder="simpleAnalyse ? '请输入退货内容，示例：\n张三\nHH1234 1\nHH1234*10' : '请输入退货的内容，示例：\n张三\nHH1234 红色 38\nHH1234 黑色 39*1 40*2'"
                        :autosize="{ minRows: 10 }"></el-input>
                    <el-switch v-model="simpleAnalyse" active-text="简洁版" style="float:left;margin-top: 15px;" />
                    <el-button type="primary" :loading="analyseLoading" style="float:right;margin-top: 10px;" @click="handleAnalyse">提交</el-button>
                    <el-button type="primary" slot="reference">一键退货</el-button>
                </el-popover>
            </div>
        </template>
        <el-form ref="editForm" :model="form" :inline="true" label-position="right" class="editForm" style="padding-right:0px;">
            <el-form-item prop="PurchaserName" label="客户：">
                <el-tooltip effect="dark" :content="PurchaserNameQQ" :disabled="PurchaserNameQQ == ''" placement="bottom">
                    <el-autocomplete :disabled="purchaser != null" v-model="PurchaserNameQQ" value-key="PurchaserName"
                        :fetch-suggestions="querySearch" @select="handleSeletedPurchaser($event)" style="width:150px;"
                        @change="handleMatchPurchaser"></el-autocomplete>
                </el-tooltip>
            </el-form-item>
            <el-form-item prop="ReceivePayment" label="实退金额：">
                <el-input :disabled="purchaser != null" v-model="form.ReceivePayment" style="width:120px;"></el-input>
            </el-form-item>
            <el-form-item v-if="$store.state.routes.systemSetting.MultiWarehouse" label="仓库：">
                <warehouse-select :value.sync="form.WID" select_style="width:115px;"></warehouse-select>
            </el-form-item>
            <el-form-item v-if="$store.state.routes.systemSetting.MultiStore" label="档口：">
                <store-select :value.sync="form.StoreID" :set_default="true" select_style="width:115px;"></store-select>
            </el-form-item>
            <el-form-item style="float: right;margin-right:0px;" v-show="purchaser == null" label="退货时间：">
                <span class="time">{{ time }} </span>
                <el-radio-group @input="changeTime" v-model="timeType">
                    <el-radio :label="0">现在</el-radio>
                    <el-radio :label="1">昨天</el-radio>
                    <el-radio :label="2">
                        <el-date-picker :disabled="timeType != 2" v-model="form.CreateTime" :clearable="false" type="date" placeholder="选择日期"
                            value-format="yyyy-MM-dd" style="width:130px;">
                        </el-date-picker>
                    </el-radio>
                </el-radio-group>
            </el-form-item>
        </el-form>
        <el-table ref="gridTable" :data="tableData" class="refundTable" border height="350">
            <el-table-column prop="ItemNo" label="货号" header-align="center" align="center" width="150">
                <template slot-scope="{ row,$index }">
                    <el-autocomplete :placeholder="form.PurchaserName != '' ? '请输入货号' : '请先选择客户'" :disabled="form.PurchaserName == ''"
                        v-model="row.ItemNo" :trigger-on-focus="false" value-key="ItemNo" :fetch-suggestions="querySearchItemNo"
                        @change="handleMatchItemNo(row, $index)" @select="handleSeletedItemNo(row, $index, $event)"></el-autocomplete>
                </template>
            </el-table-column>
            <el-table-column prop="SpecName" label="规格" header-align="center" align="center">
                <template slot-scope="{ row,$index }">
                    {{ row.SpecName }}
                    <span v-if="row.ProductID > 0" class="el-icon-edit-outline hand" @click="showSpecDialog(row, $index)"></span>
                </template>
            </el-table-column>
            <el-table-column prop="Num" header-align="center" align="center" label="数量" width="155">
                <template slot-scope="{ row }">
                    <el-input-number v-model.number="row.Num" :min="1" size="mini" :step="1" :precision="0" @change="handleInput($event, row)"
                        @input="handleInput($event, row)"></el-input-number>
                </template>
            </el-table-column>
            <el-table-column prop="Price" header-align="center" align="center" label="商品价" width="100">
                <template slot-scope="{ row }">
                    <el-input v-model="row.Price" v-format.float></el-input>
                </template>
            </el-table-column>
            <el-table-column prop="SettlePrice" header-align="center" align="center" label="指定价" width="100">
                <template slot-scope="{ row }">
                    <el-input v-model="row.SettlePrice" @input="handleInput($event, row)" v-format.float></el-input>
                </template>
            </el-table-column>
            <el-table-column prop="Payment" header-align="center" align="center" label="金额" width="100">
                <template slot-scope="{ row }">
                    {{ row.Payment | decimalFormat }}
                </template>
            </el-table-column>
            <el-table-column header-align="center" align="center" label="校验客户报单记录">
                <el-table-column prop="TotalTakedNum" header-align="center" align="center" label="提货累计" />
                <el-table-column prop="TotalRefundNum" header-align="center" align="center" label="已退累计" />
                <el-table-column prop="TotalRemainNum" header-align="center" align="center" label="可退数量">
                    <template slot-scope="{ row }">
                        <span v-if="row.ProductID > 0 && row.TotalTakedNum < row.TotalRefundNum" style="color:red;">0</span>
                        <span v-else style="color:green;">{{ row.ProductID > 0 ? (row.TotalTakedNum -
                            row.TotalRefundNum) : '' }}</span>
                    </template>
                </el-table-column>
            </el-table-column>
            <el-table-column prop="" header-align="center" align="center" label="操作" width="80">
                <template slot-scope="{ row,$index }">
                    <el-button type="danger" @click="handleRowClick(row, $index)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>

        <div slot="footer" class="dialog-footer">
            <span style="position: absolute; left: 0;font-size: 18px;">
                <span style="font-weight: bold;">退货合计</span>：
                <span style="color:#F56C6C;padding: 0 5px;">{{ form.RefundNum }}</span>双
                <span style="color:#F56C6C;padding: 0 5px;">{{ form.RefundFee }}</span>元
            </span>
            <el-switch style="margin-right:10px;" v-if="$store.state.routes.systemSetting.WmsEnabled && purchaser == null"
                v-model="$store.state.routes.systemSetting.AutoRefund" active-text="自动入库"
                @change="$store.dispatch('routes/changeSettingItem', { AutoRefund: $store.state.routes.systemSetting.AutoRefund })" />
            <el-button type="default" @click="handleCancel">取消</el-button>
            <el-button type="danger" v-if="purchaser == null" @click="handleSave('credit')">欠款</el-button>
            <el-button type="warning" v-if="purchaser == null" @click="handleSave('cash')">已转现金</el-button>
            <el-button type="success" v-if="purchaser == null" @click="handleSave('wechat')">已转微信</el-button>
            <el-button type="primary" v-if="purchaser == null" @click="handleSave('alipay')">已转支付宝</el-button>
            <el-button type="primary" v-if="purchaser != null" @click="handleSave(null)">确定</el-button>
        </div>
        <el-dialog v-dialog-drag title="选择规格" width="400px" :visible="selectPropsDialogVisible" :close-on-click-modal="false"
            @close="selectPropsDialogVisible = false" append-to-body>
            <div v-if="SpecData.Colors.length > 0">
                <div class="div_prop_item">颜色：</div>
                <div :class="'div_prop_value' + (item.IsCheck ? ' current' : '')" @click="item.IsCheck = !item.IsCheck"
                    v-for="(item, index) in SpecData.Colors" :key="index">
                    <div :title="item.Color">{{ item.Value }}</div>
                </div>
            </div>
            <div v-if="SpecData.Sizes.length > 0">
                <div class="div_prop_item">尺码：</div>
                <div :class="'div_prop_value' + (item.IsCheck ? ' current' : '')" @click="item.IsCheck = !item.IsCheck"
                    v-for="(item, index) in SpecData.Sizes" :key="index">
                    <div :title="item.Size">{{ item.Value }}</div>
                </div>
            </div>
            <div style="color:red;font-size:16px;text-align:center;">支持多选，多选后批量新增</div>
            <div slot="footer" class="dialog-footer">
                <el-button size="small" @click="selectPropsDialogVisible = false">关闭</el-button>
                <el-button type="primary" size="small" @click="saveSelectPropsDialog">保存</el-button>
            </div>
        </el-dialog>

    </el-dialog>
</template>

<script>
import { submit } from "@/api/base";
import { getDate } from '@/utils/tool';
import dayjs from "dayjs";
import WarehouseSelect from "@/views/components/common/WarehouseSelect.vue";
import StoreSelect from "@/views/components/common/StoreSelect.vue";
export default {
    name: "AddRefund",
    components: {
        WarehouseSelect,
        StoreSelect
    },
    data() {
        return {
            visible: false,
            tableData: [],
            searchItems: [],
            searchPurchases: [],
            purchaserProducts: {},
            timeType: 0,
            time: '',
            autoRefund: false,
            PurchaserNameQQ: "",
            form: {
                CreateTime: '',
                TradeID: 0,
                PurchaserID: "",
                PurchaserName: "",
                RefundNum: 0,
                RefundFee: 0,
                ReceivePayment: 0,
                WID: 0,
                StoreID: 0,
            },
            data: null,//临时变量
            timeout: null,
            purchaser: null,
            selectPropsDialogVisible: false,
            SpecData: { ItemNo: null, Specs: null, Colors: [], Sizes: [] },
            analyseLoading: false,
            analyseContent: '',//一键退货待解析的内容
            simpleAnalyse: false,
            timeOut: null,
        };
    },
    methods: {
        async handleAnalyse() {
            if (this.analyseContent == '') {
                this.$message({ type: "error", message: "退货的报单内容不能为空" });
                return;
            }
            this.analyseLoading = true;
            let content = this.analyseContent;
            let contentList = content.split('\n');
            if (contentList[0] != this.form.PurchaserName) {
                var results = this.searchPurchases.filter(x => x.Name.toLowerCase() == contentList[0].toLowerCase());
                if (results.length > 0) {
                    if (!this.form.PurchaserID) {
                        this.form.PurchaserID = results[0].PurchaserID;
                        this.form.PurchaserName = results[0].Name;
                        this.PurchaserNameQQ = results[0].PurchaserName;
                    }
                    else {
                        contentList[0] = this.form.PurchaserName;
                        content = contentList.join('\n');
                    }
                }
                else {
                    content = this.form.PurchaserName + '\n' + content;
                }
            }
            let res = await submit("/api/stallRefund/analyse", { Content: content, IsSimple: this.simpleAnalyse }).catch((error) => {
                this.analyseLoading = false;
            });
            this.analyseLoading = false;
            if (res.data) {
                var list = this.tableData.filter(x => x.ProductID != 0);
                res.data.forEach(item => {
                    if (list.some(x => x.ProductID == item.ProductID && x.SpecID == item.SpecID)) {
                        for (let i = 0; i < list.length; i++) {
                            if (list[i].ProductID == item.ProductID && list[i].SpecID == item.SpecID) {
                                list[i] = item;
                                break;
                            }
                        }
                    } else {
                        list.push(item);
                    }
                });
                list = list.sort(function (a, b) {
                    if (a.ItemNo !== b.ItemNo) return a.ItemNo < b.ItemNo ? -1 : 1
                    else if (a.SpecName !== b.SpecName) return a.SpecName < b.SpecName ? -1 : 1
                });
                this.tableData = list;
                this.addEmptyRow();
            }
            if (res.msg) {
                this.$message({ type: "error", message: res.msg });
                return;
            }
            this.analyseContent = '';
            this.$refs.popover.doClose();
        },
        init(purchaser, tradeID, storeID) {
            var that = this;
            that.time = dayjs().format('HH:mm:ss');
            that.timeType = 0;
            that.changeTime();
            that.visible = true;
            if (purchaser) {
                that.form.TradeID = tradeID;
                that.purchaser = purchaser;
                that.form.PurchaserID = purchaser.PurchaserID;
                that.form.PurchaserName = purchaser.PurchaserName;
                that.PurchaserNameQQ = purchaser.PurchaserName;
            }
            else {
                that.purchaser = null;
                that.form.TradeID = 0;
                that.form.PurchaserID = "";
                that.form.PurchaserName = "";
                that.PurchaserNameQQ = "";
            }
            that.form.StoreID = typeof (storeID) != 'undefined' ? storeID : 0;
            that.form.RefundNum = 0;
            that.form.RefundFee = 0;
            that.form.ReceivePayment = 0;
            that.$nextTick(async () => {
                that.addEmptyRow();
                if (!purchaser){
                    await that.loadPurchases();
                }
            });
        },
        changeTime() {
            switch (this.timeType) {
                case 0:
                    this.form.CreateTime = getDate();
                    break;
                case 1:
                    this.form.CreateTime = getDate(-1);
                    break;
            }
        },
        addEmptyRow() {
            this.tableData.push({
                ItemNo: "", SpecName: "", Num: 1, Price: 0, SettlePrice: 0, Payment: 0, SpecID: 0, ProductID: 0
            });
        },
        handleInput(e, row) {
            row.Payment = parseInt(row.Num) * parseFloat(row.SettlePrice);
            this.getStatistics();
        },
        getStatistics() {
            var total = 0;
            let totalNum = 0;
            if (this.tableData.length >= 0) {
                this.tableData.forEach((item) => {
                    if (item.ItemNo && item.SettlePrice) {
                        totalNum += parseInt(item.Num);
                        total += parseInt(item.Num) * parseFloat(item.SettlePrice);
                    }
                });
            }
            this.form.RefundFee = parseFloat(total.toFixed(2));
            this.form.RefundNum = totalNum;
            this.form.ReceivePayment = total;
        },
        handleRowClick(row, rowIndex) {
            if (this.tableData.length > 1) {
                var lastRow = this.tableData[rowIndex];
                if (lastRow.ItemNo == "") {
                    return;
                }
                this.tableData.splice(rowIndex, 1);
                this.getStatistics();
            }
        },
        //客户名称模糊搜索
        async querySearch(queryString, callback) {
            if (this.purchaser == null) {
                // 调用 callback 返回建议列表的数据
                var results = queryString ? this.searchPurchases.filter(x => {
                    return (x.Name.toLowerCase().indexOf(queryString.toLowerCase()) >= 0);
                }) : this.searchPurchases;
                callback(results);
            }
        },
        async loadPurchases() {
            const { data } = await submit("/api/stall/getPurchaserList", {
                searchValue: '', ToPage: false
            });
            data.forEach(d => {
                d.PurchaserName = d.Name + (d.QQ ? '(' + d.QQ + ')' : '');
            });
            this.searchPurchases = data;
        },
        async handleSeletedPurchaser(selectedRow) {
            this.form.PurchaserID = selectedRow.PurchaserID;
            this.form.PurchaserName = selectedRow.Name;
            this.PurchaserNameQQ = selectedRow.PurchaserName;
            await this.loadPurchaserProducts();
        },
        async handleMatchPurchaser() {
            if (this.PurchaserNameQQ.indexOf(this.form.PurchaserName) != 0) {
                this.form.PurchaserID = '';
                this.form.PurchaserName = this.PurchaserNameQQ;
            }
        },
        handleSeletedItemNo(row, rowIndex, selectedRow, fromMatch = false) {
            let item = { ...selectedRow };
            item.Num = parseInt(row.Num);
            item.Payment = item.SettlePrice * item.Num;
            item.OldItemNo = item.ItemNo;
            if (!this.tableData.some((x, idx) => idx != rowIndex && x.ProductID == item.ProductID && x.SpecID == item.SpecID)) {
                this.tableData.splice(rowIndex, 1, item);
            } else {
                if (fromMatch) {
                    this.tableData.splice(rowIndex, 1);
                } else {
                    this.tableData[rowIndex].ItemNo = this.tableData[rowIndex].OldItemNo;
                }
            }
            this.getStatistics();
            this.$nextTick(() => {
                if (!this.tableData.some(x => x.ProductID == 0)) {
                    this.addEmptyRow();
                }
            });
        },
        handleMatchItemNo(row, rowIndex) {
            var that = this;
            clearTimeout(that.timeOut);
            that.timeOut = setTimeout(() => {
                let selectedRow = that.searchItems.filter((i) => i.ItemNo.toLowerCase() == row.ItemNo.toLowerCase());
                var productIdx = -1;
                if (selectedRow.length > 0) {
                    for (let i = 0; i < that.tableData.length; i++) {
                        if (i != rowIndex && that.tableData[i].ProductID == selectedRow[0].ProductID && that.tableData[i].SpecID == selectedRow[0].SpecID) {
                            productIdx = i;
                            break;
                        }
                    }
                    if (productIdx < 0) {
                        that.handleSeletedItemNo(row, rowIndex, selectedRow[0], true);
                    } else {
                        that.$baseMessage("已存在货号【" + row.ItemNo + "】的商品", "error");
                        if (that.tableData[rowIndex].ProductID <= 0) {
                            that.tableData[rowIndex].ItemNo = "";
                            that.tableData[rowIndex].Price = 0;
                            that.tableData[rowIndex].Cost = 0;
                            that.tableData[rowIndex].SettlePrice = 0;
                            that.tableData[rowIndex].Payment = 0;
                        } else {
                            that.tableData[rowIndex].ItemNo = that.tableData[rowIndex].OldItemNo;
                        }
                    }
                } else {
                    //that.$baseMessage("未找到货号【" + row.ItemNo + "】的商品", "error");
                    if (that.tableData[rowIndex].ProductID > 0 && that.tableData[rowIndex].OldItemNo) {
                        that.tableData[rowIndex].ItemNo = that.tableData[rowIndex].OldItemNo;
                    }
                    if (that.tableData[rowIndex].ProductID <= 0) {
                        that.tableData[rowIndex].ItemNo = "";
                        that.tableData[rowIndex].Price = 0;
                        that.tableData[rowIndex].Cost = 0;
                        that.tableData[rowIndex].SettlePrice = 0;
                        that.tableData[rowIndex].Payment = 0;
                    }
                }
                if (!that.tableData.some(x => x.ProductID == 0)) {
                    that.addEmptyRow();
                }
            }, 100);
        },
        async querySearchItemNo(query, callback) {
            var that = this;
            if (query !== '') {
                clearTimeout(that.timeout);
                that.timeout = setTimeout(async () => {//加上延时，避免段时间输入太多而请求太多次
                    await that.loadPurchaserProducts();
                    if (callback != null) {
                        var results = query ? this.searchItems.filter(x => {
                            return (x.ItemNo.toLowerCase().indexOf(query.toLowerCase()) >= 0);
                        }) : this.searchItems;
                        callback(results);
                    }
                }, 200);
            }
        },
        async loadPurchaserProducts() {
            var that = this;
            var key = that.form.PurchaserID + '_' + that.form.PurchaserName;
            if (typeof (that.purchaserProducts[key]) == 'undefined' || that.purchaserProducts[key].length <= 0) {
                const { data } = await submit("/api/stallRefund/getProducts", {
                    Keyword: '', CheckStall: true, PurchaserID: that.form.PurchaserID, PurchaserName: that.form.PurchaserName
                });
                that.purchaserProducts[key] = data;
                that.searchItems = data;
            } else {
                that.searchItems = that.purchaserProducts[key];
            }
        },
        //选择规格弹窗
        async showSpecDialog(row, rowIndex) {
            if (!this.SpecData.ItemNo || this.SpecData.ItemNo != row.ItemNo) {
                const { data, msg } = await submit("/api/stallRefund/getSpecs", {
                    ItemNo: row.ItemNo, ProductID: row.ProductID, CheckStall: true, PurchaserID: this.form.PurchaserID, PurchaserName: this.form.PurchaserName
                });
                if (msg != null && msg != '') {
                    this.$baseMessage(msg);
                    return;
                }
                this.SpecData = { ItemNo: row.ItemNo, Specs: data, Colors: [], Sizes: [] };
            }
            this.SpecData.Index = rowIndex;
            this.SpecData.Specs.forEach(spec => {
                if (spec.Color != '' && !this.SpecData.Colors.some(t => t.Value == spec.Color)) {
                    this.SpecData.Colors.push({ Value: spec.Color, IsCheck: false });
                }
                if (spec.Size != '' && !this.SpecData.Sizes.some(t => t.Value == spec.Size)) {
                    this.SpecData.Sizes.push({ Value: spec.Size, IsCheck: false });
                }
            });
            this.selectPropsDialogVisible = true;
        },
        //保存选择
        saveSelectPropsDialog() {
            var colors = this.SpecData.Colors.filter(d => d.IsCheck);
            var sizes = this.SpecData.Sizes.filter(d => d.IsCheck);
            if (this.SpecData.Colors.length > 0 && colors.length == 0) {
                this.$baseMessageBox("请选择颜色");
                return;
            }
            if (this.SpecData.Sizes.length > 0 && sizes.length == 0) {
                this.$baseMessageBox("请选择尺码");
                return;
            }
            colors = colors.map(d => d.Value);
            sizes = sizes.map(d => d.Value);
            var specs = this.SpecData.Specs.filter(s => colors.includes(s.Color) && sizes.includes(s.Size));
            if (specs.length == 0) {
                this.$baseMessageBox("选择的颜色尺码不存在");
                return;
            }
            if (specs.length == 1 || this.tableData[this.SpecData.Index].SpecID == 0) {
                let spec = { ...specs[0] };
                if (!this.tableData.some(t => t.ProductID == spec.ProductID && t.SpecID == spec.SpecID && t.Color == spec.Color && t.Size == spec.Size)) {
                    spec.Num = parseInt(this.tableData[this.SpecData.Index].Num);
                    spec.Payment = parseInt(spec.Num) * parseFloat(spec.SettlePrice);
                    this.tableData.splice(this.SpecData.Index, 1, { ...spec });
                    specs.splice(0, 1);
                }
            }
            if (specs.length > 0) {
                for (let i = 0; i < specs.length; i++) {
                    if (!this.tableData.some(t => t.ProductID == specs[i].ProductID && t.SpecID == specs[i].SpecID && t.Color == specs[i].Color && t.Size == specs[i].Size)) {
                        this.tableData.splice(this.tableData.length - 1, 0, { ...specs[i] });
                    }
                }
            }
            this.getStatistics();
            this.selectPropsDialogVisible = false;
        },
        createStateFilter(queryString) {
            return (state) => {
                return (
                    state.value.toLowerCase().indexOf(queryString.toLowerCase()) >= 0
                );
            };
        },
        getFormData(type) {
            var data = { ...this.form };
            data.Details = [];
            this.tableData.forEach((item) => {
                if (item.ItemNo != "") {
                    if (item.Num == "" || parseInt(item.Num) <= 0) {
                        data.Error = "货号【" + item.ItemNo + "】的数量要大于0";
                    }
                    if (item.Price == "" || parseFloat(item.Price) <= 0) {
                        data.Error = "货号【" + item.ItemNo + "】的商品价要大于0";
                    }
                    if (item.SettlePrice == "" || parseFloat(item.SettlePrice) <= 0) {
                        data.Error = "货号【" + item.ItemNo + "】的指定价要大于0";
                    }
                    data.Details.push(item);
                }
            });
            return data;
        },
        async handleSave(type) {
            var formData = this.getFormData(type);
            if (formData.Error && formData.Error != "") {
                this.$baseMessage(formData.Error, "error");
                formData.Error = '';
                return;
            }
            if (formData.PurchaserName == "") {
                this.$baseMessage("请输入要退货的客户名称", "error"); return;
            }
            if (formData.Details.length <= 0) {
                this.$baseMessage("请添加要退货的商品信息", "error"); return;
            }
            formData.PayType = type;
            formData.CreateTime += ' ' + this.time;

            const { data } = await submit("/api/stallRefund/addRefundInfo", formData);
            this.$baseMessage('保存成功', "success");
            if (type != null) {
                this.$emit("handleAddSuccess");
            }
            else {
                this.$emit("handleAddRefundItems", data);
            }
            this.handleCancel();
        },
        handleCancel() {
            this.tableData = [];
            this.purchaserProducts = {};
            this.searchItems = [];
            this.searchPurchases = [];
            this.visible = false;
        }
    },
};
</script>

<style lang="scss" scoped>
.dialog-footer {
    position: relative;

    .checkbox-block {
        position: absolute;
        left: 28px;
        top: 8px;
    }

    .el-button {
        width: 100px !important;
        padding: 10px 10px !important;
    }
}

.div_prop_item {
    display: inline-block;
}

.div_prop_value {
    display: inline-block;
    vertical-align: top;
    margin-bottom: 9px;
    margin-right: 4.5px;
    margin-left: 4.5px;
    padding: 2px;
    font-size: 14px;
    line-height: 14px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    color: #333;
    text-overflow: ellipsis;
    padding: 0 8px;
    white-space: nowrap;
    overflow: hidden;
    line-height: 30px;
    vertical-align: middle;
    background-color: rgba(0, 0, 0, .06);
    border-radius: 5px;
    cursor: pointer;
    border: 1px solid transparent;
    text-align: center;
    position: relative;
}

.div_prop_value.current {
    color: #409EFF;
    border-color: #409EFF;
    background-color: #fff7f3;
}

.div_prop_value.disabled {
    cursor: not-allowed;
    color: #ccc;
    background-color: rgba(0, 0, 0, .06);
}

.time {
    color: #F56C6C;
    font-size: 25px;
}

.editForm {
    .el-input {
        max-width: 140px;
    }

    ::v-deep {
        .el-form-item__label {
            padding-right: 0px;
        }
    }

    .el-radio {
        margin-left: 10px;
        margin-right: 0px;
    }
}
</style>